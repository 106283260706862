import React, { useState } from 'react'
import HtmlToReactParser from 'html-to-react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'

import { useStyles } from './styles'

/**
 * Process HTML String and return a react component
 * This also handles processing of the HTML, so its where we can make changes,
 * ie: style, node structure, to the HTML content sent from the server.
 */
const parsePrivacyHtmlString = (htmlString: string) => {
  // If there is no value then return empty string
  if (!htmlString) {
    return null
  }

  // Create a new parser instance
  const htmlToReactParser = new HtmlToReactParser.Parser()

  // Add preproccesing instructions to add custom styles to the privacy policy html
  var preprocessingInstructions = [
    {
      shouldPreprocessNode: function (node: any) {
        return node.name === 'html' || node.name === 'body'
      },
      preprocessNode: function (node: any) {
        node.attribs = { class: 'html-class' }
      },
    },
  ]

  // Establish our processing instructions
  const processNodeDefinitions = new HtmlToReactParser.ProcessNodeDefinitions(
    React
  )
  const processingInstructions = [
    {
      // Handle the default case (process the node as is)
      shouldProcessNode: function (node: any) {
        return true
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]

  // Run the processing and return the React Element it returns
  return htmlToReactParser.parseWithInstructions(
    htmlString,
    () => true,
    processingInstructions,
    preprocessingInstructions
  )
}

interface Policy {
  Accepted: boolean
  Content: string
  CreatedAt: Date
  Method: string
  PolicyID: number
  Retractable: boolean
  TypeDescription: string
  TypeID: number
  UpdatedAt: Date
  UserID: number
  Version: string
}

interface Props {
  open: boolean
  isNew: boolean
  privacyPolicy?: Policy
  handleClose(): void
  onDecline(): void
}

export const PrivacyDialog = ({
  open,
  isNew,
  handleClose,
  onDecline,
  privacyPolicy,
}: Props) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false)

  const classes = useStyles()

  const titleText = isNew ? 'One more step!' : 'Update'

  const subtitleText = isNew
    ? 'Please review and accept our Privacy Policy to get started.'
    : 'Our Privacy Policy has been updated! Please review below and press accept to continue.'

  const policyContent =
    privacyPolicy && privacyPolicy.Content ? privacyPolicy.Content : ''

  const warningModal = warningModalOpen ? (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {'Are you sure you wish to decline the Privacy Policy?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Doing so will stop you from using Zero Health online services and log
          you out of the website.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setWarningModalOpen(false)} color="primary">
          No
        </Button>
        <Button onClick={onDecline} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  ) : null

  return (
    <>
      <Dialog onClose={handleClose} open={open} data-cy="privacy-policy-dialog">
        <DialogTitle disableTypography>
          <h1 className={classes.dialogTitle}>{titleText}</h1>
          <p className={classes.dialogSubtitle}>{subtitleText}</p>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <div
              data-cy="privacy-policy-html-string"
              className={classes.policyContainer}>
              {parsePrivacyHtmlString(policyContent)}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="privacy-policy-decline-button"
            className={classes.declineButton}
            onClick={() => setWarningModalOpen(true)}>
            Decline
          </Button>
          <Button
            data-cy="privacy-policy-accept-button"
            className={classes.acceptButton}
            onClick={handleClose}
            color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      {warningModal}
    </>
  )
}
