import styled from 'styled-components'
import { COLORS } from '../../utils/colors'
import CircularProgress from '@material-ui/core/CircularProgress'

export const SearchFrame = styled.iframe`
  height: 120%;
  width: 100%;
  border: none;
  padding-bottom: 75px;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const DesktopLandingContainer = styled.section`
  max-width: 1008px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const DesktopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  color: ${COLORS.MEMBERS.OCEAN};
`

export const LoadingIndicatorDesktop = styled(CircularProgress)`
  position: absolute;
  top: 0;
  margin-top: 50%;
`

export const LoadingIndicatorMobile = styled(CircularProgress)`
  position: absolute;
  top: 0;
  margin-top: 60%;
  left: 0;
  margin-left: 45%;
  color: ${COLORS.MEMBERS.OCEAN} !important;
`
