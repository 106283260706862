import styled from 'styled-components'

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10%;
`

export const DesktopLandingContainer = styled.section`
  max-width: 1008px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const DesktopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`
