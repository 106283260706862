import axios from 'axios'

const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL
const axiosClient = axios.create({
  baseUrl: S3_BASE_URL,
})

export function get(url) {
  const requestUrl = S3_BASE_URL + url

  return axiosClient.get(requestUrl)
}
