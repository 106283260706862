import React, { Component } from 'react'
import './utils/logger'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import reducers from './reducers'
import MemberContainer from './containers/MemberContainer'
import LandingContainer from './containers/LandingContainer'
import { ConfirmProfile } from './views/ConfirmProfile'
import { Profile } from './views/Profile'
import { IDCardDetail } from './views/IDCard'
import { NotFound } from './views/NotFound'
import { Unsuccessful } from './views/Unsuccessful'
import { LoginPage } from './views/Login'
import { EnterCode } from './views/EnterCode'
import { Support } from './views/Support'
import { FindCare } from './views/FindCare'
import { Settings } from './views/Settings'
import Config from './Config'
import LoginCallback from './components/LoginCallback'
import { getItem } from './services/local'
import { loggedIn } from './services/api'
import './App.scss'

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore)

export default class App extends Component {
  render() {
    const firstVisit = !getItem('visited')

    return (
      <Provider store={createStoreWithMiddleware(reducers)}>
        <Config />
        <Router>
          {/* todo: get this animation working, it messes with styling */}
          <Switch className="route-wrapper" wrapperComponent={false}>
            <Route
              exact
              path="/"
              render={(props) => (
                <LandingContainer
                  isLoggedIn={loggedIn}
                  firstVisit={firstVisit}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/main"
              render={(props) => (
                <MemberContainer firstVisit={firstVisit} {...props} />
              )}
            />
            <Route
              exact
              path="/how-it-works"
              render={(props) => <MemberContainer initStep={2} {...props} />}
            />
            <Route
              exact
              path="/how-to-use"
              render={(props) => <MemberContainer initStep={3} {...props} />}
            />
            <Route
              exact
              path="/verify"
              render={(props) => <MemberContainer initStep={4} {...props} />}
            />
            <Route
              exact
              path="/confirm"
              render={(props) => <MemberContainer initStep={5} {...props} />}
            />
            <Route
              exact
              path="/signup"
              render={(props) => <MemberContainer initStep={6} {...props} />}
            />
            <Route
              exact
              path="/successful"
              render={(props) => <MemberContainer initStep={7} {...props} />}
            />
            <Route
              exact
              path="/profile"
              render={(props) => <Profile {...props} />}
            />
            <Route
              exact
              path="/confirm_profile"
              render={(props) => <ConfirmProfile {...props} />}
            />
            <Route
              exact
              path="/unsuccessful"
              render={(props) => <Unsuccessful {...props} />}
            />
            <Route
              exact
              path="/login"
              render={(props) => <LoginPage {...props} />}
            />
            <Route
              exact
              path="/code"
              render={(props) => <EnterCode {...props} />}
            />
            <Route
              exact
              path="/support"
              render={(props) => <Support {...props} />}
            />
            <Route
              path="/login_callback"
              render={(props) => <LoginCallback {...props} />}
            />
            <Route
              exact
              path="/member/:id/idcard"
              render={(props) => <IDCardDetail {...props} />}
            />
            <Route
              exact
              path="/findcare"
              render={(props) => <FindCare {...props} />}
            />
            <Route
              exact
              path="/settings"
              render={(props) => <Settings {...props} />}
            />
            <Route path="*" render={(props) => <NotFound />} />
          </Switch>
        </Router>
      </Provider>
    )
  }
}
