import React from 'react'
import ProfileContainer from '../../containers/ProfileContainer'
import { View } from '../../components/View'
import './styles.scss'

export const Profile = (props) => {
  return (
    <View id="view-profile" name="view-profile">
      <ProfileContainer />
      <div className="text-center" style={{ padding: '2rem 0' }}>
        <button className="button yellow" onClick={props.history.goBack}>
          Back
        </button>
      </div>
    </View>
  )
}
