import React from 'react'
import EnterCodeContainer from '../../containers/EnterCodeContainer'
import { View } from '../../components/View'
import './styles.scss'

export const EnterCode = (props) => {
  return (
    <View id="view-enter-code" name="view-enter-code">
      <div className="main-content">
        <p>
          You should have received an email with a 6-digit code. Please enter
          that code here and click 'Login' to continue
        </p>
      </div>
      <EnterCodeContainer />
    </View>
  )
}
