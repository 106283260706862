export const steps = {
  initial: {
    value: 1,
    route: '/',
  },
  howItWorks: {
    value: 2,
    route: '/how-it-works',
  },
  howToUse: {
    value: 3,
    route: '/how-to-use',
  },
  verify: {
    value: 4,
    route: '/verify',
  },
  confirm: {
    value: 5,
    route: '/confirm',
  },
  signup: {
    value: 6,
    route: '/signup',
  },
  complete: {
    value: 7,
    route: '/main',
  },
  total: 6,
}
