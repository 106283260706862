import {
  GETTING_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  PARSING_CALLBACK_TOKEN,
  PARSE_CALLBACK_TOKEN_SUCCESS,
  PARSE_CALLBACK_TOKEN_ERROR,
  SENDING_CODE,
  SEND_CODE_ERROR,
  SEND_CODE_SUCCESS,
  LOGGING_IN_WITH_CODE,
  LOGIN_WITH_CODE_SUCCESS,
  LOGIN_WITH_CODE_ERROR,
  GET_USER_AUTH_STATUS,
  RESET_AUTH_STATE,
  CLEAR_VISITED,
} from '../actions/AuthActions'

// eslint-disable-next-line
export default function (state = null, action) {
  switch (action.type) {
    case GETTING_USER_INFO: {
      return {
        ...state,
        working: true,
        gettingInfo: true,
        user: null,
        accessGroups: null,
        error: null,
      }
    }
    case GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        working: false,
        gettingInfo: false,
        user: action.payload.Data.User,
        accessGroups: action.payload.Data.AccessGroups,
        userFetched: true,
        error: null,
      }
    }
    case GET_USER_INFO_ERROR: {
      return {
        ...state,
        working: false,
        gettingInfo: false,
        user: null,
        accessGroups: null,
        userFetched: true,
        error: action.error,
      }
    }
    case PARSING_CALLBACK_TOKEN: {
      return {
        ...state,
        working: true,
        parsingToken: true,
        authd: false,
        error: null,
      }
    }
    case PARSE_CALLBACK_TOKEN_SUCCESS: {
      return {
        ...state,
        working: false,
        parsingToken: false,
        authd: true,
        error: null,
      }
    }
    case PARSE_CALLBACK_TOKEN_ERROR: {
      return {
        ...state,
        working: false,
        parsingToken: false,
        authd: false,
        error: action.error,
      }
    }
    case SENDING_CODE: {
      return {
        ...state,
        working: true,
        sendingCode: true,
        codeSent: false,
        codeSentError: false,
      }
    }
    case SEND_CODE_SUCCESS: {
      return {
        ...state,
        working: false,
        sendingCode: false,
        codeSent: true,
        codeSentError: false,
      }
    }
    case SEND_CODE_ERROR: {
      console.debug(action)
      return {
        ...state,
        working: false,
        sendingCode: false,
        codeSent: false,
        codeSentError: action.error,
      }
    }
    case LOGGING_IN_WITH_CODE: {
      return {
        ...state,
        working: true,
        loggingIn: true,
        loggedIn: false,
        loginError: false,
      }
    }
    case LOGIN_WITH_CODE_SUCCESS: {
      return {
        ...state,
        working: false,
        loggingIn: false,
        loggedIn: true,
        loginError: false,
      }
    }
    case LOGIN_WITH_CODE_ERROR: {
      console.debug(action)
      return {
        ...state,
        working: false,
        loggingIn: false,
        loggedIn: false,
        loginError: action.error,
      }
    }
    case GET_USER_AUTH_STATUS: {
      return {
        ...state,
        status: action.payload,
      }
    }
    case CLEAR_VISITED: {
      return {
        ...state,
      }
    }
    case RESET_AUTH_STATE: {
      return {
        sending: false,
        codeSent: false,
        codeSentError: false,
        authd: false,
        error: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
