import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { ConfirmIdentity } from '../components/ConfirmIdentity'

class ConfirmIdentityContainer extends Component {
  handleNotMeClick = () => {
    // clear application state?
    const { history } = this.props
    history.push(`/unsuccessful`)
  }

  render() {
    const { verifiedMember } = this.props
    if (verifiedMember === undefined) {
      this.props.ifMissingMember()
      return null
    }
    return (
      <div>
        <ConfirmIdentity
          member={verifiedMember}
          onConfirmClick={this.props.onSuccess}
          onRejectClick={this.handleNotMeClick}
        />
      </div>
    )
  }
}

ConfirmIdentityContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  ifMissingMember: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    verifiedMember: state.verify.member,
    linked: state.verify.linked,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfirmIdentityContainer)
