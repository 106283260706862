import * as firehose from '../services/firehose'

export function rLogout() {
  firehose.put({
    data: {},
    event: 'landing.logout',
    descr: 'User is logging out',
  })
}

export function rConfirmProfile() {
  firehose.put({
    data: {},
    event: 'landing.confirmProfile',
    descr: 'User is redirecting to confirm profile',
  })
}

export function rCallPHA() {
  firehose.put({
    data: {},
    event: 'landing.callPHA',
    descr: 'User is clicking to call the PHA',
  })
}

export function rClickSearch() {
  firehose.put({
    data: {},
    event: 'landing.clickSearch',
    descr: 'User is clicking to view the search page',
  })
}

export function rClickChat() {
  firehose.put({
    data: {},
    event: 'landing.clickChat',
    descr: 'User is clicking to view the chat page',
  })
}

export function rClickWebsite() {
  firehose.put({
    data: {},
    event: 'landing.clickWebsite',
    descr: 'User is clicking to view the website',
  })
}

export function rClickRegister() {
  firehose.put({
    data: {},
    event: 'landing.clickRegister',
    descr: 'User is clicking IsNewHere',
  })
}

export function rClickLogin() {
  firehose.put({
    data: {},
    event: 'landing.clickLogin',
    descr: 'User is clicking Login',
  })
}

export function rClickSupport() {
  firehose.put({
    data: {},
    event: 'landing.clickSupport',
    descr: 'User is clicking to view the support page',
  })
}
