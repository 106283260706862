import {
  validString,
  validZip,
  validDate,
  validEmail,
  validPhone,
  validState,
} from '../utils'

const Profile = {
  fields: {
    FirstName: '',
    LastName: '',
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zip: '',
    Dob: '',
    ContactEmail: '',
    ContactPhone: '',
  },
  validators: {
    FirstName: { isValid: validString, msg: 'You must include a first name' },
    LastName: { isValid: validString, msg: 'You must include a last name' },
    Address1: {
      isValid: validString,
      msg: 'You must include a street address',
    },
    City: { isValid: validString, msg: 'You must include a city' },
    State: { isValid: validState, msg: 'You must include a valid state' },
    Zip: { isValid: validZip, msg: 'You must include a valid zip code' },
    Dob: { isValid: validDate, msg: 'You must include a valid date of birth' },
    ContactEmail: {
      isValid: validEmail,
      msg: 'Your email must be valid or empty',
    },
    ContactPhone: {
      isValid: validPhone,
      msg: 'Your phone number must be valid or empty',
    },
  },
}

export default Profile
