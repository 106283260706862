import React, { Component } from 'react'
import './print.scss'
require('./style.scss')
const logoFillColor = '#fbb517'

class Logo extends Component {
  render() {
    const { props } = this
    const viewBox = `0 0 ${props.size} ${props.size}`
    const radius = 50
    const strokeLen = 284
    const className = ['progresser']
    let strokeOff = strokeLen - strokeLen * 0.01 * props.progress
    if (props.working) {
      className.push('spinner')
      strokeOff = 250
    }

    return (
      <a className="logo-link" href="/">
        <svg
          className="logo-loader"
          width={props.size}
          height={props.size}
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg">
          <g className="outline">
            <circle
              stroke={props.outlineColor}
              strokeOpacity={props.outlineOpacity}
              cx={radius}
              cy={radius}
              r={radius - props.outlineWidth}
              strokeWidth={props.outlineWidth}
              fill="none"
            />
            <circle
              className={className.join(' ')}
              stroke={props.progressColor}
              strokeWidth={props.outlineWidth - 2}
              strokeDasharray={strokeLen}
              strokeDashoffset={strokeOff}
              strokeOpacity="1"
              cx={radius}
              cy={radius}
              r={radius - props.outlineWidth}
              fill="none"
            />
          </g>
          <g className="logo">
            <circle
              strokeWidth="0"
              cx={radius}
              cy={radius}
              r={radius - props.outlineWidth * 1.5}
              fill={logoFillColor}
            />
            <text
              className="text-stroked"
              x="50"
              y="60"
              fontSize="38"
              strokeWidth="0.75"
              stroke="#ffffff"
              fill="none"
              letterSpacing="-2"
              fontWeight="100"
              textAnchor="middle"
              fontFamily="Helvetica">
              zero
            </text>
            <text
              className="text-filled"
              x="50"
              y="60"
              fontSize="38"
              strokeWidth="0"
              fill="#ffffff"
              letterSpacing="-2"
              fontWeight="100"
              textAnchor="middle"
              fontFamily="Helvetica">
              zero
            </text>
          </g>
        </svg>
      </a>
    )
  }
}

Logo.defaultProps = {
  size: '100',
  outlineWidth: 5,
  outlineOpacity: '0.2',
  outlineColor: '#d9d9d9',
  progressColor: '#a9a9a9',
  progress: 0,
  working: false,
}

export default Logo
