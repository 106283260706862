import * as api from '../services/api'
import { isWorking } from './GlobalActions'

export const VERIFY_MEMBER_SUCCESS = 'VERIFY_MEMBER_SUCCESS'
export const VERIFYING_MEMBER = 'VERIFYING_MEMBER'
export const VERIFY_MEMBER_ERROR = 'VERIFY_MEMBER_ERROR'
export const CLEAR_VERIFY_STATE = 'CLEAR_VERIFY_STATE'

const STATUS_FORBIDDEN = 403

export const verifyMember = (params) => {
  return (dispatch) => {
    console.debug('Verifying member: ', params)
    dispatch(verifyingMember(true))
    dispatch(isWorking(true))
    api
      .put('/public/verify_identity_claim', params)
      .then((result) => {
        const { Data } = result
        if (Data) {
          dispatch(verifyMemberSuccess(result))
          dispatch(isWorking(false))
          console.debug('Successfully verified member: ', params)
        } else {
          const isMinor = result.Error?.StatusCode === STATUS_FORBIDDEN

          dispatch(verifyMemberError(result.Error, isMinor))
          dispatch(isWorking(false))
          console.debug('Error verifying member: ', params)
        }
      })
      .catch((err) => {
        dispatch(verifyMemberError(err, false))
      })
  }
}

export const verifyMemberSuccess = (res) => {
  return {
    type: VERIFY_MEMBER_SUCCESS,
    payload: res,
  }
}

export const verifyingMember = (working) => {
  return {
    type: VERIFYING_MEMBER,
    working,
  }
}

export const verifyMemberError = (err, isMinor) => {
  return {
    type: VERIFY_MEMBER_ERROR,
    error: err,
    isMinor: isMinor,
  }
}

export const clearVerifyState = () => {
  return {
    type: CLEAR_VERIFY_STATE,
  }
}
