import React, { useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Grid from '@material-ui/core/Grid'
import { FeatureGateDialog } from '../FeatureGateDialog'
import {
  updateLoginRedirectRoute,
  updateMenuPosition,
} from '../../actions/GlobalActions'
import {
  rClickAccountLoggedIn,
  rClickAccountNotLoggedIn,
  rClickHome,
  rClickIDCardLoggedIn,
  rClickIDCardNotLoggedIn,
  rClickSearch,
} from '../../analytics/MenuAnalytics'
import {
  Button,
  ButtonText,
  GridItem,
  HighlightedButton,
} from './styledComponents'

interface Props {
  updateLoginRedirectRoute(routeToRedirectTo: string): void
  updateMenuPosition(selection: number): void
  menuPosition?: number
  loginRedirectRoute: string
  idCards?: any
  isLoggedIn: boolean
}

export const DesktopOptionsMenuComponent = (props: Props) => {
  const history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleMenuSelection = (selection: number) => {
    props.updateMenuPosition(selection)
    switch (selection) {
      case 0:
        history.push('/')
        rClickHome()
        break
      case 1:
        props.updateLoginRedirectRoute('/idcard')
        // Navigate to the id card page if possible if not gate the feature
        if (props.isLoggedIn) {
          history.push(`/member/${props.idCards.MemberID}/idcard`)
          rClickIDCardLoggedIn()
        } else {
          setDialogOpen(true)
          rClickIDCardNotLoggedIn()
        }
        break
      case 2:
        history.push('/findcare')
        rClickSearch()
        break
      case 3:
        // Navigate to the id card page if possible if not gate the feature
        if (props.isLoggedIn) {
          history.push('/profile')
          rClickAccountLoggedIn()
        } else {
          setDialogOpen(true)
          rClickAccountNotLoggedIn()
        }
        break
      default:
        break
    }
  }

  const menuItemNames = ['Home', 'ID Card', 'Find Care', 'Account']

  // Create the number of components we have based on names and highlight the correct one
  const menuComponents = menuItemNames.map((name, index) => {
    /**
     * If this menu item is the selected then return a highlighted version or no index is selected
     * and this is the home page
     */
    if (props.menuPosition === index || (!props.menuPosition && index === 0)) {
      return (
        <GridItem item key={name}>
          <HighlightedButton
            onClick={() => handleMenuSelection(index)}
            data-cy={`${name}-selected`}>
            <ButtonText>{name}</ButtonText>
          </HighlightedButton>
        </GridItem>
      )
    }

    // This item is not selected and will return normally
    return (
      <GridItem item key={name}>
        <Button onClick={() => handleMenuSelection(index)} data-cy={name}>
          <ButtonText>{name}</ButtonText>
        </Button>
      </GridItem>
    )
  })

  return (
    <>
      <Grid container justify="space-between">
        {menuComponents}
      </Grid>
      <FeatureGateDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      />
    </>
  )
}

// Redux configuration
function mapStateToProps(state: any) {
  return {
    menuPosition: state.global.menuPosition,
    idCards: state.members.idCards,
    loginRedirectRoute: state.global.loginRedirectRoute,
    isLoggedIn: state.auth ? !!state.auth.user : null,
  }
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    { updateMenuPosition, updateLoginRedirectRoute },
    dispatch
  )
}

const DesktopOptionsMenu = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DesktopOptionsMenuComponent)

export { DesktopOptionsMenu }
