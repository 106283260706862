import {
  persistRedirectRoute,
  getPersistedRedirectRoute,
} from '../services/api'
export const WORKING = 'WORKING'
export const UPDATE_MENU_POSITION = 'UPDATE_MENU_POSITION'
export const UPDATE_LOGIN_REDIRECT_ROUTE = 'UPDATE_LOGIN_REDIRECT_ROUTE'

export const isWorking = (working) => {
  return {
    type: WORKING,
    working,
  }
}

/**
 * Update the position of the mobile menu
 */
export const updateMenuPosition = (position) => {
  return {
    position,
    type: UPDATE_MENU_POSITION,
  }
}

/**
 *  Update the route that the browswer wil redirect too after
 *  a successful login or registration.
 * @param {string} route: route to navigate to after login
 */
export const updateLoginRedirectRoute = (route) => {
  // Persist the route to local storage
  persistRedirectRoute(route)

  return {
    route,
    type: UPDATE_LOGIN_REDIRECT_ROUTE,
  }
}

/**
 * Get the redirect route from local stroage (if it exists)
 * and save it in Redux
 */
export const getLoginRedirectFromLocalStorageAndSave = () => {
  const route = getPersistedRedirectRoute()
  return {
    route,
    type: UPDATE_LOGIN_REDIRECT_ROUTE,
  }
}
