import uuid from 'uuid/v1'
import { getCurrentUser } from './user'
import aws from '../services/aws'
/* AWS is imported via a cdn in public/index.js */
// included via cdn in public/index.html

/* eslint-disable */

const firehose = new aws.Firehose({
  region: 'us-west-2',
})

const sessionId = uuid()
let sequencer = 0
/* eslint-enable */

/* descr - friendly description of the event */
/* event type - consistent identifier for the event to group on */
/* data - free form data related to the event */
export function put(payload, cb) {
  sequencer = sequencer + 1
  payload.sessionId = sessionId
  payload.sequencer = sequencer
  payload.cognitoUserId = aws.TZCGetCognitoUserKey()
  payload.tzcUserId = getCurrentUser()
  payload.timestamp = new Date()
  payload.data = JSON.stringify(payload.data)

  const params = {
    DeliveryStreamName: process.env.REACT_APP_ANALYTICS_STREAM_NAME,
    Record: {
      Data: JSON.stringify(payload),
    },
  }

  firehose.putRecord(params, (err, res) => {
    if (err) console.debug(err, err.stack)
    if (cb !== undefined) {
      cb(err, res)
    }
  })
}
