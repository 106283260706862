import styled from 'styled-components'
import { COLORS } from '../../utils/colors'
import Grid from '@material-ui/core/Grid'

export const GridItem = styled(Grid)`
  text-align: center;
`

export const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 2px 4px;
  margin: 10px 0;
  :hover {
    color: ${COLORS.MEMBERS.OCEAN};
    cursor: pointer;
    border-bottom: 2px solid ${COLORS.MEMBERS.OCEAN};
  }

  :active {
    outline: none;
  }
`

export const HighlightedButton = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid ${COLORS.MEMBERS.OCEAN};
  padding: 2px 4px;
  margin: 10px 0;

  :hover {
    color: ${COLORS.MEMBERS.OCEAN};
    cursor: pointer;
    border-bottom: 2px solid ${COLORS.MEMBERS.OCEAN};
  }

  :focus {
    border: none !important;
  }
`

export const ButtonText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Raleway';
  color: ${COLORS.BW.GUNMETAL};
`
