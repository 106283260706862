import React, { Component } from 'react'
import './style.scss'

class errorPage extends Component {
  render() {
    const { details, title } = this.props
    return (
      <div className="error-page">
        <h3>{title || 'Our apologies'}</h3>
        {details ? (
          <p className="error-details">{details}</p>
        ) : (
          <p>
            It looks like we have encountered an error, or are otherwise unable
            to process your request.
          </p>
        )}
        <p>
          Please <a href="https://zero.health/contact-us">contact us</a> or{' '}
          <a href="tel:18558160001">call</a> for assistance.
        </p>
      </div>
    )
  }
}

export const ErrorPage = errorPage

// use details to add a request id or other info
ErrorPage.defaultProps = {
  details: '',
}
