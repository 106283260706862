import {
  WORKING,
  UPDATE_MENU_POSITION,
  UPDATE_LOGIN_REDIRECT_ROUTE,
} from '../actions/GlobalActions'

// eslint-disable-next-line
export default function (state = null, action) {
  switch (action.type) {
    case WORKING: {
      console.debug('Working triggered: ', action.working)

      return {
        ...state,
        working: action.working,
      }
    }
    case UPDATE_MENU_POSITION: {
      return {
        ...state,
        menuPosition: action.position,
      }
    }

    case UPDATE_LOGIN_REDIRECT_ROUTE: {
      console.debug(
        'Update login redirect route made it into the reducer',
        action
      )
      return {
        ...state,
        loginRedirectRoute: action.route,
      }
    }
    default:
      return {
        ...state,
        error: false,
      }
  }
}
