/* singleton to abstract and make localstorage access safe (if ephemeral) in the case that it doesnt exist or we dont have access to it */
const localStorage = window.localStorage
const localMemory = {}

export function getItem(key) {
  if (!localStorage.getItem) {
    return localMemory[key]
  }

  return localStorage.getItem(key)
}

export function removeItem(key) {
  if (!localStorage.removeItem) {
    return delete localMemory[key]
  }

  return localStorage.removeItem(key)
}

export function setItem(key, value) {
  if (!localStorage.setItem) {
    localMemory[key] = value
    return true
  }

  return localStorage.setItem(key, value)
}
