import * as firehose from '../services/firehose'

export function rSendMagicLink(data) {
  firehose.put({
    data,
    event: 'auth.sendMagicLink',
    descr: 'User or program has requested a magic (login) link to be sent',
  })
}

export function rSendMagicLinkExisting(data) {
  firehose.put({
    data,
    event: 'auth.sendMagicLinkExisting',
    descr:
      'User has tried to sign up and already has an account. Link sent to existing account',
  })
}

export function rSignup(data) {
  firehose.put({
    data,
    event: 'auth.sendMagicLink',
    descr: 'User or program has requested a magic (login) link to be sent',
  })
}

export function rDoAuthentication(data) {
  firehose.put({
    data,
    event: 'auth.doAuthentication',
    descr: 'Program has received auth information successfully',
  })
}

export function rNoUser(data) {
  firehose.put({
    data,
    event: 'auth.noUser',
    descr: 'User does not exist for login information',
  })
}

export function rGetUserInfoSuccess(data) {
  firehose.put({
    data,
    event: 'auth.getUserInfoSuccess',
    descr: 'Program has fetched user info successfully',
  })
}

export function rClickLogin(data) {
  firehose.put({
    data,
    event: 'auth.clickLogin ',
    descr: 'User is requesting an email with code or link to login',
  })
}

export function rClickSignup() {
  firehose.put({
    data: {},
    event: 'auth.clickSignup',
    descr: 'User clicked "signup" on the login page',
  })
}

export function rLoginRequestSuccess() {
  firehose.put({
    data: {},
    event: 'auth.loginEmailRequestSuccess',
    descr: 'User has requested a login email successfully',
  })
}

export function rClickSendCode(data) {
  firehose.put({
    data,
    event: 'auth.clickSendCode ',
    descr: 'User is validating a login code',
  })
}

export function rSendCodeEmail(data) {
  firehose.put({
    data,
    event: 'auth.sendCodeEmail',
    descr: 'We have successfully sent an email with a code to login',
  })
}

export function rSendCodeEmailExisting(data) {
  firehose.put({
    data,
    event: 'auth.sendCodeEmailExisting',
    descr:
      'User has tried to sign up and already has an account. Code sent to existing account',
  })
}

export function rClickSignupEnterCode() {
  firehose.put({
    data: {},
    event: 'auth.clickSignupEnterCode',
    descr: 'User clicked "signup" on the enter code page',
  })
}

export function rLoginWithCode() {
  firehose.put({
    data: {},
    event: 'auth.sendCodeSuccess',
    descr: 'User has successfully verified a code',
  })
}
