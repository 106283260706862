import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getConfig } from './actions/ConfigActions'

class Config extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const { gettingConfig } = this.props
    if (!gettingConfig) {
      this.props.getConfig()
    }
  }

  render() {
    return <div />
  }
}

function mapStateToProps(state) {
  return {
    error: state.config.error,
    gettingConfig: state.config.gettingConfig,
    config: state.config.config,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getConfig }, dispatch)
}

Config.propTypes = {
  error: PropTypes.bool,
  config: PropTypes.object,
  gettingConfig: PropTypes.bool,
}

Config.defaultProps = {
  gettingConfig: false,
  config: null,
  error: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(Config)
