import { combineReducers } from 'redux'
import verify from './reducer_verify'
import members from './reducer_members'
import auth from './reducer_auth'
import users from './reducer_users'
import global from './reducer_global'
import passkit from './reducer_passkit'
import config from './reducer_config'

const rootReducer = combineReducers({
  verify,
  members,
  users,
  auth,
  global,
  passkit,
  config,
})

export default rootReducer
