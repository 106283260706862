import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Walkthrough from '../../components/Walkthrough'
import { Container } from '../../components/Container'
import { TopHeader } from '../../components/TopHeader'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { steps } from '../../models/Walkthrough'
import {
  rLoadFromQS,
  rGoToLogin,
  rGoToVerify,
  rFinish,
} from '../../analytics/WalkthroughAnalytics'
import _ from 'lodash'

class WalkthroughContainer extends Component {
  constructor() {
    super()
    this.state = {
      progress: 0,
      animateLeave: false,
      value: 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { step } = this.props
    const foundStep = this.findStep(step)
    this.onStepChange(foundStep, steps.total)
    rLoadFromQS({ step: foundStep, state: this.state })
  }

  componentDidMount() {
    const { step } = this.props
    const foundStep = this.findStep(step)
    this.onStepChange(foundStep, steps.total)
    rLoadFromQS({ step: foundStep, state: this.state })
  }

  findStep = (val) => {
    return _.find(steps, { value: val })
  }

  goToVerify = () => {
    rGoToVerify(this.state)
    this.onStepChange(steps.verify, steps.total)
  }

  goToLogin = () => {
    rGoToLogin(this.state)
    this.props.history.push(`/login`)
  }

  onStepChange = (step, of) => {
    const { history } = this.props
    const intStep = step.value * 1
    steps.total = of // hacky
    this.setState({
      progress: ((intStep - 1) / of) * 100,
    })

    if (step.value !== this.props.step) {
      history.push(step.route)
    }
  }

  onFinish = () => {
    const { history } = this.props
    this.setState({
      progress: 100,
    })
    history.push('/successful')
    rFinish(this.state)
  }

  renderFooter = () => {
    const { step } = this.props

    if (step !== steps.initial.value) {
      return null
    }
  }

  render() {
    return (
      <section id="walkthrough" style={this.props.style}>
        <Container classes="intro">
          <TopHeader />
          <Walkthrough
            step={this.props.step}
            onFinish={this.onFinish}
            onChangeStep={this.onStepChange}></Walkthrough>
        </Container>
      </section>
    )
  }
}

WalkthroughContainer.propTypes = {
  step: PropTypes.number,
  swapPage: PropTypes.func,
}

WalkthroughContainer.defaultProps = {
  step: 1,
  swapPage() {},
}

function mapStateToProps(state) {
  console.debug('Redux state in da hood', state)
  return {
    working: state.global.working,
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WalkthroughContainer)
