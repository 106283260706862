import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

class confirmIdentity extends Component {
  handleConfirmClick = () => {
    this.props.onConfirmClick()
  }

  render() {
    const { member } = this.props

    return (
      <div id="confirm-identity">
        <div className="details-block">
          <h3>Welcome {member.FirstName}</h3>
          <p>
            Please confirm these details and we will get you set up with an
            account with Zero Health.
          </p>
          <p>
            <span className="preface">Employer:</span> {member.EmployerName}
          </p>
        </div>
        <div className="btn-footer">
          <button
            className="button yellow"
            onClick={this.handleConfirmClick}
            data-cy="confirm-identity-submit">
            That's Me!
          </button>
          <Link to="/support" data-cy="confirm-identity-reject">
            Not me!
          </Link>
        </div>
      </div>
    )
  }
}

export const ConfirmIdentity = confirmIdentity
