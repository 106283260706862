import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { View } from '../../components/View'
import { getCognitoUserID } from '../../services/aws'
import zenDesk from '../../utils/zendeskWidget'
import { Button } from '@material-ui/core'
import { rClickChat } from '../../analytics/LandingAnalytics'
import './styles.scss'

class support extends Component {
  state = {}

  componentDidMount() {
    const userId = getCognitoUserID()
    this.setState({ userId })
  }

  render() {
    const { details, title } = this.props
    const { userId } = this.state

    // Inialize Zendesk widget so user can contact support
    const onClickChat = () => {
      zenDesk().then((sdk) => {
        sdk.toggle()
      })
      rClickChat()
    }

    return (
      <View id="view-support">
        <h3>{title || 'Contact Us'}</h3>
        {details ? (
          <p className="error-details">{details}</p>
        ) : (
          <p>
            You have encountered a problem while using MyZero. Please contact us
            through the button below and include this information:{' '}
          </p>
        )}
        <Button onClick={onClickChat}>Chat</Button>
        <p>{userId}</p>
        <p className="error-details">
          If you believe there was a mistake,{' '}
          <Link to="/verify">click here to try again</Link>
        </p>
      </View>
    )
  }
}

export const Support = support

// use details to add a request id or other info
Support.defaultProps = {
  message: null,
  title: null,
}

Support.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
}
