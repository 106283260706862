import React from 'react'
import classNames from 'classnames'
import { Container } from '../Container'
import { TopHeader } from '../../components/TopHeader'
import './style.scss'

export const View = (props) => {
  const { classes, containerClasses } = props
  const cls = classNames({
    view: true,
    fluid: props.fluid,
    ...classes,
  })
  return (
    <section className={cls} id={props.id} style={props.style}>
      <TopHeader />
      <Container classes={containerClasses}>{props.children}</Container>
    </section>
  )
}

View.defaultProps = {
  fluid: false,
}
