import _ from 'lodash'

const baseLogger = console
const logLevel = process.env.REACT_APP_LOG_LEVEL
const PREFIX = `###tzc-${logLevel}-logger => `

const devLogger = _.assign({}, baseLogger, {
  log: (...args) => {
    return baseLogger.log(PREFIX, ...args)
  },
  debug: (...args) => {
    return baseLogger.debug(PREFIX, ...args)
  },
})

const prodLogger = _.assign({}, baseLogger, {
  log: (...args) => {
    return null // noop
  },
  debug: (...args) => {
    return null // noop
  },
  table: (...args) => {
    return null // noop
  },
})

const logger = logLevel && logLevel === 'prd' ? prodLogger : devLogger
console.log('####################tzc#######################')
console.log(' ')
console.log(`${PREFIX} You are using the ${logLevel} logger`)
console.log(
  `${PREFIX} Switch your log level to 'Verbose' in Chrome to view all messages: https://stackoverflow.com/questions/18760213/chrome-console-log-console-debug-are-not-working`
)
console.log(' ')
console.log('####################/tzc#######################')

// eslint-disable-next-line
console = logger
