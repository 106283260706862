import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { ErrorPage } from '../views/ErrorPage'
import { IDCardList } from '../components/IDCard'
import { addIDCardToWallet } from '../actions/PasskitActions'
import {
  getUserPolicies,
  getProfileInfo,
  getIdCards,
} from '../actions/MemberActions'
import { getUserInfo } from '../actions/AuthActions'

class idCardListContainer extends Component {
  componentDidMount() {
    this.props.getIdCards()
  }

  handleItemClick = ({ id }) => {
    debugger
    this.props.history.push(`/member/${id}/idcard`)
  }

  render() {
    const { primaryIDCard, dependentIDCards, idCardError, passkitError } =
      this.props
    let error = idCardError || passkitError
    let details = ''

    if (idCardError) {
      details = `We had an issue loading your ID card(s).`
    } else {
      details = (
        <div>
          We are unable to generate an Apple Wallet pass for you at this time.{' '}
          <a href={window.location.href}>Click here to refresh</a>
        </div>
      )
    }

    return (
      <div>
        {error ? (
          <ErrorPage details={details} />
        ) : (
          <IDCardList
            addToWallet={this.props.addIDCardToWallet}
            primary={primaryIDCard}
            dependents={dependentIDCards}
            onItemClick={this.handleItemClick}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    passkitError: state.passkit.error,
    idCardError: state.members.error,
    working: state.members.working,
    primaryIDCard: state.members.idCards || {},
    dependentIDCards: state.members.idCards
      ? state.members.idCards.Dependents
      : [],
    isLoggedIn: !!state.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserPolicies,
      getProfileInfo,
      getUserInfo,
      getIdCards,
      addIDCardToWallet,
    },
    dispatch
  )
}

idCardListContainer.propTypes = {
  error: PropTypes.bool,
}

idCardListContainer.defaultProps = {
  primaryIdCard: {},
}

export const IDCardListContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(idCardListContainer)
