import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import compose from 'recompose/compose'
import { ErrorPage } from '../views/ErrorPage'
import { Login } from '../components/Login'
import { sendCode, resetAuthState, clearVisited } from '../actions/AuthActions'
import {
  rLoginRequestSuccess,
  rClickSignup,
  rClickLogin,
} from '../analytics/AuthAnalytics'

export class LoginContainer extends Component {
  state = {
    email: '',
    last4Ssn: null,
    processed: false,
  }

  componentDidMount() {
    const { location } = this.props
    const params = queryString.parse(location.search)

    if (params && params.tryAgain) {
      this.props.resetAuthState()
    }
  }

  componentWillUnmount() {
    this.props.resetAuthState()
  }

  componentWillReceiveProps(nextProps) {
    const { processed } = this.state
    if (
      nextProps &&
      nextProps.sent &&
      !nextProps.sending &&
      !nextProps.error &&
      !processed
    ) {
      this.setState({ processed: true })
      const { history } = this.props
      console.debug('Confirmed user and sent email: ', nextProps)
      rLoginRequestSuccess()
      history.push(`/code`)
      return
    }
  }

  handleLoginClick = ({ email, last4Ssn }) => {
    this.setState({ email, last4Ssn })
    this.props.sendCode({ email })
    rClickLogin({ email, last4Ssn })
  }

  handleSignup = () => {
    this.props.resetAuthState()
    this.props.clearVisited()
    this.props.history.push(`/verify`)
    rClickSignup()
  }

  getErrorDetails = (err) => {
    if (err && err.statusCode === 400 && err.code === 'bad.connection') {
      return {
        details: (
          <span>
            We did not find an account for that email address. Please{' '}
            <Link to="/verify">click here to signup</Link>
          </span>
        ),
        title: 'Please create an account',
      }
    }
    return {
      details: (
        <span>
          We encountered a problem logging you in. It could be that your code is
          expired or has already been used. If you think that is the case,
          please <Link to="/login?tryAgain=true">try again</Link>. Otherwise,
          please contact us!
        </span>
      ),
      title: 'Your code is expired',
    }
  }

  render() {
    const { error } = this.props
    const { title, details } = this.getErrorDetails(error)

    return (
      <div className="login-container">
        {error && <ErrorPage title={title} details={details} />}
        {!error && (
          <Login
            loginHandler={this.handleLoginClick}
            signupHandler={this.handleSignup}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.auth.codeSentError,
    working: state.auth.working,
    sending: state.auth.sendingCode,
    sent: state.auth.codeSent,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { resetAuthState, sendCode, clearVisited },
    dispatch
  )
}

LoginContainer.propTypes = {
  error: PropTypes.object,
  working: PropTypes.bool,
  sending: PropTypes.bool,
  sent: PropTypes.bool,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LoginContainer)
