import React from 'react'
import classNames from 'classnames'
import Logo from '../Logo'
import './styles.scss'

export const SectionHeader = (props) => {
  const styleMappings = {
    fieldset: props.hasFieldset,
    'section-header': true,
    'text-center': props.center,
    'top-header': props.noMargin,
  }

  const classes = classNames(styleMappings)
  return (
    <p className={classes} id={props.id}>
      {props.children}
    </p>
  )
}

SectionHeader.defaultProps = {
  hasFieldset: false,
  center: false,
  id: null,
}

// TODO: consider removing, unused but interesting
export const Header = (props) => {
  return (
    <header className="app-header">
      <figure>
        <a href="tel:14109878899">
          <i title="Call ZERO" className="icon-headphones header-icon" />
        </a>
      </figure>
      <Logo working={props.working} />
      <figure>
        <a href="https://zero.health/search" target="_tzc">
          <i title="Find Care" className="icon-search header-icon" />
        </a>
      </figure>
    </header>
  )
}

Header.defaultProps = {
  working: false,
}
