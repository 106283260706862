import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { NavFooter } from '../../components/NavFooter'
import VerifyContainer from '../../containers/VerifyContainer'
import SignupContainer from '../../containers/SignupContainer'
import SuccessfulContainer from '../../containers/SuccessfulContainer'
import ConfirmIdentityContainer from '../../containers/ConfirmIdentityContainer'
import { steps } from '../../models/Walkthrough'
import {
  rNavigate,
  rVerifySuccess,
  rConfirmSuccess,
  rSignupSuccess,
  rReVerify,
} from '../../analytics/WalkthroughAnalytics'
import {
  ContentContainer,
  DesktopContentContainer,
  DesktopLandingContainer,
  FlexWrapper,
} from './styledComponents'
import { isUserOnMobile } from '../../utils'
require('./style.scss')

class Walkthrough extends Component {
  state = {
    step: {
      value: 1,
      route: '/',
    },
  }

  componentDidMount() {
    const { step } = this.props
    this.setStep(step)
  }

  componentWillReceiveProps(nextProps) {
    const { step } = nextProps
    if (step && step !== this.state.step.value) {
      this.setStep(step)
    }
  }

  setStep = (val) => {
    const newStep = _.find(steps, { value: val })
    this.setState({ step: newStep })
    this.props.onChangeStep(newStep, steps.total)
  }

  movePrev = () => {
    const step =
      this.state.step.value > 1 ? this.getPrevStep(this.state.step) : 1
    this.setStep(step.value)
    rNavigate(this.state)
  }

  moveNext = () => {
    const step =
      this.state.step.value < steps.total
        ? this.getNextStep(this.state.step)
        : this.state.step
    this.setStep(step.value)
    rNavigate(this.state)
  }

  getNextStep = (cur) => {
    return _.find(steps, { value: cur.value + 1 })
  }

  getPrevStep = (cur) => {
    return _.find(steps, { value: cur.value - 1 })
  }

  handleConfirmSuccess = (params) => {
    console.debug('Do we ever even get here?', params)
    this.setState({ step: steps.signup })
    this.props.onChangeStep(steps.signup, steps.total)
    rConfirmSuccess(params)
  }

  handleVerifySuccess = (params) => {
    this.setState({ step: steps.confirm })
    this.props.onChangeStep(steps.confirm, steps.total)
    rVerifySuccess(params)
  }

  handleSignupSuccess = (params) => {
    this.setState({ step: steps.complete })
    this.props.onFinish()
    rSignupSuccess(params)
  }

  handleReVerify = () => {
    this.setState({ step: steps.verify })
    this.props.onChangeStep(steps.verify, steps.total)
    rReVerify()
  }

  renderCurrentStep = () => {
    const { step } = this.state
    switch (step.value) {
      case 1:
        return (
          <aside className="welcome-step">
            <span className="sm">Welcome to</span>
            <span className="lg">ZERO</span>
            <div className="welcome-content">
              <p>
                <span className="preface">Congratulations!</span> Your employer
                has given you and your dependents access to thousands of
                healthcare services and procedures for{' '}
                <strong>ZERO cost to you!</strong>
              </p>
              <p>
                <span className="preface">Sound too good to be true?</span> Hit
                the next button to see how it works.
              </p>
            </div>
          </aside>
        )
      case 2:
        return (
          <aside>
            <ContentContainer>
              <h3>How it works</h3>
              <p>
                <span className="preface">Simple.</span> We simplify the
                healthcare process for you and your employer through direct
                contracts with specialists and other healthcare providers. We
                save your employer enough money that you get the care you need
                for absolutely <strong>$0.</strong>
              </p>
              <p>
                <span className="preface">
                  No fees, no deductibles, no bills.
                </span>
              </p>
              <p>Up next: How do I use ZERO?</p>
            </ContentContainer>
          </aside>
        )
      case 3:
        return (
          <aside>
            <ContentContainer>
              <h3>How do I use ZERO?</h3>
              <p>
                <span className="preface">Seen your doctor lately?</span> If
                your doctor orders labwork or refers you for a procedure,
                chances are ZERO can get you that done for{' '}
                <strong>free.</strong> Just let your doctor know that you have
                ZERO and would like to schedule it through us.
              </p>
              <p>
                <span className="preface">After the doctor.</span> Call your
                Personal Health Assistant to experience concierge level service.
                They will find your referral, set up the appointment and take
                care of any details.
              </p>
            </ContentContainer>
          </aside>
        )
      case 4:
        return (
          <aside className="content-step">
            <ContentContainer>
              <VerifyContainer onSuccess={this.handleVerifySuccess} />
            </ContentContainer>
          </aside>
        )
      case 5:
        return (
          <aside className="content-step text-center">
            <ContentContainer>
              <ConfirmIdentityContainer
                onSuccess={this.handleConfirmSuccess}
                ifMissingMember={this.handleReVerify}
              />
            </ContentContainer>
          </aside>
        )
      case 6:
        return (
          <aside className="content-step">
            <ContentContainer>
              <SignupContainer
                onSignup={this.handleSignupSuccess}
                ifMissingMember={this.handleReVerify}
              />
            </ContentContainer>
          </aside>
        )
      case 7:
        return (
          <ContentContainer>
            <SuccessfulContainer />
          </ContentContainer>
        )
      default:
        return <div />
    }
  }

  render() {
    const isMobile = isUserOnMobile()

    // If the user is on Desktop return the desktop layout
    if (!isMobile) {
      return (
        <FlexWrapper>
          <DesktopLandingContainer>
            <DesktopContentContainer>
              {this.renderCurrentStep()}
            </DesktopContentContainer>
          </DesktopLandingContainer>
          <NavFooter
            onBackPress={
              this.state.step.value > 1 && this.state.step.value < 5
                ? this.movePrev
                : null
            }
            onNextPress={this.state.step.value < 4 ? this.moveNext : null}
          />
        </FlexWrapper>
      )
    }
    return (
      <div>
        {this.renderCurrentStep()}
        <NavFooter
          onBackPress={
            this.state.step.value > 1 && this.state.step.value < 5
              ? this.movePrev
              : null
          }
          onNextPress={this.state.step.value < 4 ? this.moveNext : null}
        />
      </div>
    )
  }
}
Walkthrough.defaultProps = {
  step: 1,
  onChangeStep(step, of) {
    /* noop */
  },
}

Walkthrough.propTypes = {
  onFinish: PropTypes.func.isRequired,
}

export default Walkthrough
