/**
 * Helper functions to correctly play with the ZenDesk Web Widget
 * The API can be found here: https://developer.zendesk.com/embeddables/docs/widget/introduction
 * And by API, what ZenDesk really means is this, because their docs suck: https://api.zopim.com/files/meshim/widget/controllers/LiveChatAPI-js.html
 * BUT WAIT THERE'S MORE: it might also be this one: https://api.zopim.com/web-sdk/#chat-participants
 */

declare global {
  interface Window {
    zE: any
    $zopim: any
    zESettings: Object
  }
}

type cbOnUnreadMessage = (_: number) => void
type cbOnChatStart = () => void
type cbOnChatConnected = () => void
type handlerOnStatus = (_: string) => void

interface SDK {
  toggle(): void
  hide(): void
  showWidget(): void
  isChatting(): boolean
  onChatConnected(callback: cbOnChatConnected): void
  onChatStart(callback: cbOnChatStart): void
  onUnreadMessages(callback: cbOnUnreadMessage): void
  onStatus(callback: handlerOnStatus): void
}

let promiseLoader: Promise<SDK>
export default function loadSDK(): Promise<SDK> {
  if (!promiseLoader) {
    window.zESettings = {
      webWidget: {
        offset: {
          horizontal: '0px',
          vertical: '60px',
          mobile: {
            horizontal: '0px',
            vertical: '60px',
          },
        },
      },
    }

    promiseLoader = new Promise<SDK>(
      (res, rej /* @todo: auto-reject after timeout of <X>? */) => {
        const script = document.createElement('script')
        script.id = 'ze-snippet' // THIS IS REQUIRED!
        script.type = 'text/javascript'
        script.onload = () => {
          const { zE } = window
          zE(() => {
            const { $zopim } = window
            $zopim(() => {
              // $zopim.livechat.button.hide() // uncomment to force-hide the widget button once SDK is loaded
              res({
                toggle(): void {
                  $zopim.livechat.window.show()
                },
                hide(): void {
                  $zopim.livechat.button.hide()
                },
                showWidget(): void {
                  $zopim.livechat.button.show()
                },
                isChatting(): boolean {
                  return $zopim.livechat.isChatting()
                },
                onChatConnected(
                  fn = () => {
                    /* default to no-op */
                  }
                ): void {
                  $zopim.livechat.setOnConnected(fn)
                },
                onChatStart(
                  fn = () => {
                    /* default to no-op */
                  }
                ): void {
                  $zopim.livechat.setOnChatStart(fn)
                },
                onUnreadMessages(
                  fn = (_: number) => {
                    /* default to no-op */
                  }
                ): void {
                  $zopim.livechat.setOnUnreadMsgs(fn)
                },
                onStatus(
                  fn = (_: string) => {
                    /* default to no-op */
                  }
                ): void {
                  $zopim.livechat.setOnStatus(fn)
                },
              })
            })
          })
        }
        // has to come after the onload function is defined
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_WIDGET_KEY}`
        document.querySelector<HTMLHeadElement>('head')!.appendChild(script)
      }
    )
  }
  return promiseLoader
}
