import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import compose from 'recompose/compose'
import { View } from '../View'
import { ErrorPage } from '../../views/ErrorPage'
import { withRouter, Link } from 'react-router-dom'
import {
  parseCallbackToken,
  getUserAuthStatus,
} from '../../actions/AuthActions'
import { getLoginRedirectFromLocalStorageAndSave } from '../../actions/GlobalActions'

class LoginCallback extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      processed: false,
    }

    const { location } = props
    const params = queryString.parse(location.hash)

    this.props.parseCallbackToken(params)
    /**
     *  Since we got to this page via callback we need to pull the
     *  login redirect route from local stroage and hydrate into redux
     */
    this.props.getLoginRedirectFromLocalStorageAndSave()
  }

  componentDidMount = () => {
    this.props.getUserAuthStatus()
  }

  componentWillReceiveProps = (nextProps) => {
    const { history } = this.props
    const { processed } = this.state
    if (nextProps && nextProps.authd && !processed) {
      this.setState({ processed: true })

      history.push(`/`)

      return
    }
  }

  handleError = () => {
    const { error, authStatus } = this.props
    const errorDetails = (
      <span>
        We encountered a problem logging you in. It could be that your code is
        expired or has already been used. If you think that is the case, please{' '}
        <Link to="/login?tryAgain=true">try again</Link>. Otherwise, please
        contact us!
      </span>
    )

    if (error) {
      if (error.error && error.error === 'unauthorized') {
        if (authStatus && authStatus.loggedIn) {
          return <Redirect to="/main" />
        }
      }

      return <ErrorPage details={errorDetails} />
    }

    return <div />
  }

  render() {
    return <View>{this.handleError()}</View>
  }
}

function mapStateToProps(state) {
  return {
    authStatus: state.auth.status,
    authd: state.auth.authd,
    error: state.auth.error,
    loginRedirectRoute: state.global.loginRedirectRoute,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLoginRedirectFromLocalStorageAndSave,
      parseCallbackToken,
      getUserAuthStatus,
    },
    dispatch
  )
}

LoginCallback.propTypes = {
  authd: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LoginCallback)
