import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TabNavigation } from '../../components/TabNavigation'
import { TopHeader } from '../../components/TopHeader'
import { getProfileInfo } from '../../actions/MemberActions'
import { getUserMemberships } from '../../actions/AuthActions'
import { isUserOnMobile } from '../../utils'
import {
  DesktopContentContainer,
  DesktopLandingContainer,
  FlexWrapper,
  LoadingIndicatorDesktop,
  LoadingIndicatorMobile,
  SearchFrame,
} from './styledComponents'

interface EmployerMembership {
  EmployerID: number
  EmployerName: string
  IsActive: boolean
}

const FindCareComponent = (props: any) => {
  const isMobile = isUserOnMobile()

  const [isLoading, updateIsLoading] = useState(true)
  const [employerIDs, setEmployerIDs] = useState<number[]>([])

  useEffect(() => {
    props.getProfileInfo()
    getUserMemberships().then((res: any) => {
      const idArray: number[] = []
      res?.Data?.forEach((mem: EmployerMembership) => {
        if (mem.IsActive) idArray.push(mem.EmployerID)
      })
      setEmployerIDs(idArray)
    })
    // eslint-disable-next-line
  }, []) // ts-ignore

  if (!props.zipcode && props.gettingProfileInfo) return null

  const loadingIndicatorToShowDesktop = isLoading ? (
    <LoadingIndicatorDesktop color="inherit" />
  ) : null
  const loadingIndicatorToShowMobile = isLoading ? (
    <LoadingIndicatorMobile color="inherit" />
  ) : null

  const zipQS = props.zipcode ? `zipcode=${props.zipcode}&` : ''
  const memberName = props.profileInfo
    ? `${props.profileInfo.FirstName} ${props.profileInfo.LastName}`
    : ''
  const userInfoQS = `userinfo=${btoa(JSON.stringify({ employerIDs, name: memberName }))}&`
  const url = `https://search.zero.health?${zipQS}${userInfoQS}source=myzero`

  // Render Desktop Layout if on desktop
  if (!isMobile) {
    return (
      <FlexWrapper>
        <DesktopLandingContainer>
          <TopHeader desktop />
          <DesktopContentContainer>
            <SearchFrame src={url} onLoad={() => updateIsLoading(false)} />
            {loadingIndicatorToShowDesktop}
          </DesktopContentContainer>
        </DesktopLandingContainer>
      </FlexWrapper>
    )
  }

  // Render mobile layout
  return (
    <>
      <TopHeader />
      <SearchFrame src={url} onLoad={() => updateIsLoading(false)} />
      {loadingIndicatorToShowMobile}
      <TabNavigation />
    </>
  )
}

function mapStateToProps(state: any) {
  return {
    profileInfo: state.members.profile,
    zipcode: state.members.profile ? state.members.profile.Zip : null,
  }
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({ getProfileInfo }, dispatch)
}

export const FindCare = connect(
  mapStateToProps,
  mapDispatchToProps
)(FindCareComponent)
