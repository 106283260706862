import React from 'react'
import LoginContainer from '../../containers/LoginContainer'
import { View } from '../../components/View'
import './styles.scss'

export const LoginPage = (props) => {
  return (
    <View id="view-login" name="view-login">
      <div className="main-content">
        <p>
          Fill out this form and we will send you a one-time use code to login.
        </p>
      </div>
      <LoginContainer />
    </View>
  )
}
