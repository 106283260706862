import {
  GETTING_CONFIG,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_ERROR,
} from '../actions/ConfigActions'

// eslint-disable-next-line
export default function (state = null, action) {
  switch (action.type) {
    case GETTING_CONFIG: {
      return {
        working: true,
        gettingConfig: true,
        config: null,
        features: null,
        error: false,
      }
    }
    case GET_CONFIG_SUCCESS: {
      return {
        working: false,
        gettingConfig: false,
        config: action.payload,
        features: action.payload.features,
        error: false,
      }
    }
    case GET_CONFIG_ERROR: {
      return {
        working: false,
        gettingConfig: false,
        config: null,
        features: null,
        error: true,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
