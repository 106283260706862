import _ from 'lodash'
import {
  LINKING_MEMBER,
  LINK_MEMBER_SUCCESS,
  LINK_MEMBER_ERROR,
  GET_ID_CARDS_SUCCESS,
  GETTING_ID_CARDS,
  GET_ID_CARDS_ERROR,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_ERROR,
  GETTING_PROFILE_INFO,
  SAVING_PROFILE_INFO,
  SAVE_PROFILE_INFO_ERROR,
  SAVE_PROFILE_INFO_SUCCESS,
  CONFIRMING_PROFILE_INFO,
  CONFIRM_PROFILE_INFO_ERROR,
  CONFIRM_PROFILE_INFO_SUCCESS,
  UPDATING_USER_POLICY,
  UPDATE_USER_POLICY_SUCCESS,
  UPDATE_USER_POLICY_ERROR,
  GETTING_USER_POLICIES,
  GET_USER_POLICIES_SUCCESS,
  GET_USER_POLICIES_ERROR,
} from '../actions/MemberActions'

const STATUS_CONFLICT = 409
// eslint-disable-next-line
export default function (state = null, action) {
  console.debug(action)
  switch (action.type) {
    case LINKING_MEMBER: {
      return {
        ...state,
        linking: true,
        linked: false,
        linkedMember: null,
        linkingEmail: action.payload.Email,
        error: false,
        usernameConflict: null,
        existingAccount: null,
      }
    }
    case LINK_MEMBER_SUCCESS: {
      return {
        ...state,
        linking: false,
        linked: true,
        linkedMember: action.payload.Data,
        linkingEmail: null,
        error: false,
        usernameConflict: null,
        existingAccount: null,
      }
    }
    case LINK_MEMBER_ERROR: {
      console.error('Error linking member: ', action.error, state)
      const existingEmail =
        action.error &&
        action.error.Error &&
        action.error.Error.StatusCode === STATUS_CONFLICT &&
        action.error.Data &&
        action.error.Data.Email
      const usernameConflict =
        action.error &&
        action.error.Error &&
        action.error.Error.StatusCode === STATUS_CONFLICT &&
        action.error.Error.Message === 'UsernameConflict'

      return {
        ...state,
        linking: false,
        linked: false,
        linkedMember: null,
        linkingEmail: null,
        error: action.error && action.error.Error && !existingEmail,
        usernameConflict: usernameConflict,
        existingAccount: existingEmail,
      }
    }
    case GET_ID_CARDS_ERROR: {
      return {
        ...state,
        gettingIdCards: false,
        idCards: {},
        error: true,
      }
    }
    case GET_ID_CARDS_SUCCESS: {
      return {
        ...state,
        gettingIdCards: false,
        idCards: action.payload.Data,
        error: false,
      }
    }
    case GETTING_ID_CARDS: {
      return {
        ...state,
        gettingIdCards: true,
        idCards: {},
        error: false,
      }
    }
    case GET_PROFILE_INFO_ERROR: {
      return {
        ...state,
        gettingProfileInfo: false,
        profile: null,
        confirmed: false,
        error: true,
      }
    }
    case GET_PROFILE_INFO_SUCCESS: {
      const { Data } = action.payload
      return {
        ...state,
        gettingProfileInfo: false,
        profile: action.payload.Data,
        confirmed: Data && Data.Confirmed,
        error: false,
      }
    }
    case GETTING_PROFILE_INFO: {
      return {
        ...state,
        gettingProfileInfo: true,
        profile: null,
        confirmed: false,
        error: false,
      }
    }
    case SAVE_PROFILE_INFO_ERROR: {
      return {
        ...state,
        savingProfileInfo: false,
        saveError: true,
      }
    }
    case SAVE_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        savingProfileInfo: false,
        profile: action.payload.Data,
        saveError: false,
      }
    }
    case SAVING_PROFILE_INFO: {
      return {
        ...state,
        savingProfileInfo: true,
        saveError: false,
      }
    }
    case CONFIRM_PROFILE_INFO_ERROR: {
      return {
        ...state,
        confirmingProfileInfo: false,
        confirmed: false,
        confirmError: true,
      }
    }
    case CONFIRM_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        confirmingProfileInfo: false,
        confirmed: true,
        confirmError: false,
      }
    }
    case CONFIRMING_PROFILE_INFO: {
      return {
        ...state,
        confirmingProfileInfo: true,
        confirmed: false,
        saveError: false,
      }
    }
    case UPDATING_USER_POLICY: {
      return {
        ...state,
        updatingUserPolicy: true,
      }
    }
    case UPDATE_USER_POLICY_SUCCESS: {
      const res = action.payload.Data
      const userPoliciesArr = [...state.userPolicies]
      const policyIndex = _.findIndex(userPoliciesArr, {
        PolicyID: res.PolicyID,
      })

      // Assign the new properties of the response to the object
      userPoliciesArr[policyIndex] = Object.assign(
        userPoliciesArr[policyIndex],
        res
      )

      return {
        ...state,
        userPolicies: [...userPoliciesArr],
        updatingUserPolicy: false,
      }
    }
    case UPDATE_USER_POLICY_ERROR: {
      return {
        ...state,
        updatingUserPolicy: false,
      }
    }
    case GETTING_USER_POLICIES: {
      return {
        ...state,
        gettingUserPolicies: true,
        userPolicies: [],
      }
    }
    case GET_USER_POLICIES_SUCCESS: {
      return {
        ...state,
        gettingUserPolicies: false,
        userPolicies: action.payload.Data,
      }
    }
    case GET_USER_POLICIES_ERROR: {
      return {
        ...state,
        gettingUserPolicies: false,
        userPolicies: [],
        userPoliciesError: true,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
