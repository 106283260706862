import React from 'react'
import ProfileContainer from '../../containers/ProfileContainer'
import { Redirect } from 'react-router-dom'
import { View } from '../../components/View'
import { connect } from 'react-redux'
import './styles.scss'

const confirmProfile = (props) => {
  if (props.confirmed) {
    if (props.loginRedirectRoute && props.loginRedirectRoute !== '/login') {
      // Customize the route to redirect if the user got to this login flow from a specific page
      switch (props.loginRedirectRoute) {
        case '/idcard': {
          return props.idCards && props.idCards.MemberID ? (
            <Redirect to={`/member/${props.idCards.MemberID}/idcard`} />
          ) : (
            <Redirect to="/" />
          )
        }
        default:
          return <Redirect to={props.loginRedirectRoute} />
      }
    }

    return <Redirect to="/" />
  }

  return (
    <View id="view-profile" name="view-profile" fluid>
      <div className="section-header text-center top-header">
        Please take a minute to confirm your information.
      </div>
      <div className="text-center note-text">
        This will not affect your employer records, but helps us ensure better
        data quality.
      </div>
      <ProfileContainer confirm />
    </View>
  )
}

function mapStateToProps(state) {
  return {
    loginRedirectRoute: state.global.loginRedirectRoute,
    idCards: state.members.idCards,
    working: state.global.working,
    error: state.members.error,
    confirmed: state.members.confirmed,
  }
}

export const ConfirmProfile = connect(mapStateToProps)(confirmProfile)
