import styled from 'styled-components'
import { COLORS } from '../../utils/colors'

// Styled Components
export const FooterContainer = styled.div`
  background-color: ${COLORS.BW.WHITE};
  border-top: 1px solid ${COLORS.BW.CLOUDS};
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  padding: 10px 24px;
  position: fixed;
  right: 0;
  z-index: 100;
`

export const FooterChildren = styled.div`
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
`

export const BackButton = styled.button`
  background: ${COLORS.BW.WHITE};
  color: #001837;
  font-weight: 500;
  height: 38px;
  line-height: 37px;
  min-width: 38px;
  padding: 0 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid ${COLORS.BW.CLOUDS};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: 'Raleway';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background 100ms ease-out;
  transition: background 100ms ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
`

export const NextButton = styled.button`
  background: ${COLORS.MEMBERS.OCEAN};
  color: #fff;
  height: 38px;
  line-height: 37px;
  min-width: 38px;
  padding: 0 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: 'Raleway';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background 100ms ease-out;
  transition: background 100ms ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
`
