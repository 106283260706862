import { getItem } from './local'
const COGNITO_USER_KEY = `aws.cognito.identity-id.${process.env.REACT_APP_ANALYTICS_COGNITO_POOL_ID}`

const AWS = window.AWS || getMock()
AWS.config.region = process.env.REACT_APP_AWS_REGION
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_ANALYTICS_COGNITO_POOL_ID,
})
AWS.TZCGetCognitoUserKey = () => {
  return getItem(COGNITO_USER_KEY)
}

export default AWS

export function getCognitoUserID() {
  return getItem(COGNITO_USER_KEY)
}

function getMock() {
  return {
    config: {},
    CognitoIdentityCredentials: class mock {},
    Firehose: class mock {
      putRecord() {}
    },
  }
}
