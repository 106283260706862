import * as firehose from '../services/firehose'

export function rSaveProfile() {
  firehose.put({
    data: {},
    event: 'profile.saveProfile',
    descr: 'User is saving their profile',
  })
}

export function rConfirmProfile() {
  firehose.put({
    data: {},
    event: 'profile.confirmProfile',
    descr: 'User is confirming profile changes',
  })
}

export function rCancelEdit() {
  firehose.put({
    data: {},
    event: 'profile.cancelEdit',
    descr: 'User has cancelled their edits',
  })
}

export function rTogglePolicy(data) {
  firehose.put({
    data,
    event: 'profile.updatePolicy',
    descr: 'User toggled a policy',
  })
}
