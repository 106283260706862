import React, { Component } from 'react'
import './style.scss'

export class EnterCode extends Component {
  state = {
    showValidations: false,
    code: '',
  }

  isCodeValid = () => {
    const { code } = this.state

    return code && code.length === 6
  }

  getFieldValidations = () => {
    return {
      code: this.isCodeValid(),
    }
  }

  isValid = () => {
    return this.isCodeValid()
  }

  handleLogin = (e) => {
    const { code } = this.state

    this.setState({ showValidations: true })
    if (!this.isValid()) return
    this.props.loginHandler({ code })
  }

  handleCodeChange = (e) => {
    const { value } = e.target
    if (value && value.length > 6) return // max length of 6

    this.setState({ code: value })
  }

  render() {
    const { showValidations, code } = this.state
    const { error } = this.props
    const { title, details } = error
    const valid = this.getFieldValidations()

    return (
      <div>
        <form className="enter-code-form">
          <div className="fieldset-container">
            <fieldset
              className={
                !valid.code && showValidations ? 'input-error' : undefined
              }>
              <legend>Code</legend>
              <input
                data-cy="enter-code-input"
                type="text"
                placeholder="6-digit code"
                value={code}
                onChange={this.handleCodeChange}
              />
            </fieldset>
          </div>
          {error.err && (
            <div className="error-block">
              <div>{title}</div>
              <div>{details}</div>
            </div>
          )}
          <div className="text-center">
            <button
              data-cy="enter-code-submit"
              onClick={this.handleLogin}
              type="button"
              className="button yellow"
              style={{ margin: '-5px 0 0.75rem' }}>
              Login
            </button>
          </div>
        </form>
      </div>
    )
  }
}

EnterCode.defaultProps = {
  error: {
    err: false,
  },
}
