import React, { useState } from 'react'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { useHistory, withRouter } from 'react-router-dom'
import SettingsIcon from '@material-ui/icons/Settings'
import { DesktopOptionsMenu } from '../../components/DesktopOptionsMenu'
import zeroLogo from '../../assets/img/zero-logo.png'
import { COLORS } from '../../utils/colors'
import { rClickLogo } from '../../analytics/MenuAnalytics'
import { FeatureGateDialog } from '../FeatureGateDialog'
import {
  updateLoginRedirectRoute,
  updateMenuPosition,
} from '../../actions/GlobalActions'

// Types
interface Props {
  updateLoginRedirectRoute(): void
  updateMenuPosition(position: number): void
  showSettingsIcon?: boolean
  desktop?: boolean
  isLoggedIn: boolean
}

// Styled Components
const TopHeaderContainer = styled(Grid)`
  padding: 15px;
`

const LogoImage = styled.img`
  width: 100px;
  height: 29px;
`

const FlexContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const SettingsIconStlyed = styled(SettingsIcon)`
  flex: 1;
`

const SettingsButton = styled.button`
  color: ${COLORS.BW.GUNMETAL};
  background: transparent;
  border: none;
`

const LogoButton = styled.button`
  background-color: transparent;
  border: none;

  :hover {
    cursor: pointer;
  }

  :active {
    outline: none !important;
  }
`

// Component Code
export const TopHeaderComponent = (props: Props) => {
  const history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false)

  const settingsIconToDisplay = props.showSettingsIcon ? (
    <SettingsIconStlyed style={{ fontSize: 32 }} />
  ) : null

  // Function to the feature gate when the settings icon is clicked
  const onSettingsIconClick = () => {
    // Navigate to the profile page if possible if not gate the feature
    if (props.isLoggedIn) {
      history.push('/profile')
    } else {
      setDialogOpen(true)
    }
  }

  // Callback function for when a user clicks on the main logo
  const onLogoClick = () => {
    props.updateMenuPosition(0)
    history.push('/')
    rClickLogo()
  }

  const desktopLayout = (
    <TopHeaderContainer container id="top-header">
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ width: '100%', height: '100%' }}>
          <LogoButton onClick={onLogoClick}>
            <LogoImage src={zeroLogo} />
          </LogoButton>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} />
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ width: '100%', height: '100%' }}>
          <DesktopOptionsMenu />
        </Grid>
      </Grid>
    </TopHeaderContainer>
  )

  const mobileLayout = (
    <TopHeaderContainer container id="top-header">
      <Grid item xs={4} sm={4} md={4} lg={4} />
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ width: '100%', height: '100%' }}>
          <LogoButton onClick={onLogoClick}>
            <LogoImage src={zeroLogo} alt="ZERO Logo" />
          </LogoButton>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <FlexContainer>
          <SettingsButton
            onClick={onSettingsIconClick}
            data-cy="mobile-settings-button">
            {settingsIconToDisplay}
          </SettingsButton>
        </FlexContainer>
      </Grid>
      <FeatureGateDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      />
    </TopHeaderContainer>
  )

  return props.desktop ? desktopLayout : mobileLayout
}

// Redux configuration
function mapStateToProps(state: any) {
  return {
    isLoggedIn: state.auth ? !!state.auth.user : null,
  }
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    { updateLoginRedirectRoute, updateMenuPosition },
    dispatch
  )
}

export const TopHeader = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopHeaderComponent)
