import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../utils/colors'

export const useStyles = makeStyles({
  policyContainer: {
    overflow: 'auto',
  },
  dialogTitle: {
    fontSize: 24,
    paddingTop: 0,
    fontFamily: 'Raleway',
  },
  dialogSubtitle: {
    fontSize: 18,
    fontFamily: 'Raleway',
  },
  h1: {
    paddingTop: 0,
    fontFamily: 'Raleway',
  },
  acceptButton: {
    backgroundColor: COLORS.MEMBERS.OCEAN,
    color: COLORS.BW.WHITE,
    fontFamily: 'Raleway',
  },
  declineButton: {
    fontFamily: 'Raleway',
  },
})
