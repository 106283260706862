import React from 'react'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { IDCardListContainer } from '../../containers/IDCardContainer'
import { TabNavigation } from '../../components/TabNavigation'
import { TopHeader } from '../../components/TopHeader'
import { isUserOnMobile } from '../../utils'

// Styled Components
const ContentContainer = styled(Grid)`
  width: 100%;
  padding-bottom: 75px;
`
const ExplanationTextBox = styled(Grid)`
  padding: 25px;
  text-align: center;
`

// Styled Components
const DesktopLandingContainer = styled.section`
  max-width: 1008px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const DesktopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const idCardDetail = (props) => {
  const { match } = props
  const { params } = match
  const isMobile = isUserOnMobile()

  // If on desktop then render the desktop layout
  if (!isMobile) {
    return (
      <FlexWrapper>
        <DesktopLandingContainer>
          <TopHeader desktop />
          <DesktopContentContainer>
            <ContentContainer
              container
              spacing={1}
              direction="column"
              justify="space-between"
              alignItems="center">
              <ExplanationTextBox container>
                <Grid item xs={1} sm={1} md={1} lg={1} />
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <div id="id-card-description">
                    Present this card when you are getting labwork done at any
                    Quest location, or when you show up for a ZERO scheduled
                    appointment!
                  </div>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} />
              </ExplanationTextBox>
              <IDCardListContainer id={params.id} />
            </ContentContainer>
          </DesktopContentContainer>
        </DesktopLandingContainer>
      </FlexWrapper>
    )
  }

  // Not desktop, return the mobile layout
  return (
    <>
      <TopHeader />
      <ContentContainer
        container
        spacing={1}
        direction="column"
        justify="space-between"
        alignItems="center">
        <ExplanationTextBox container>
          <Grid item xs={1} sm={1} md={1} lg={1} />
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <div id="id-card-description">
              Present this card when you are getting labwork done at any Quest
              location, or when you show up for a ZERO scheduled appointment!
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} />
        </ExplanationTextBox>
        <IDCardListContainer id={params.id} />
        <TabNavigation />
      </ContentContainer>
    </>
  )
}

export const IDCardDetail = withRouter(idCardDetail)
