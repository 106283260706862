import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { ErrorPage } from '../views/ErrorPage'
import { Signup } from '../components/Signup'
import { sendCode } from '../actions/AuthActions'
import { linkMemberToUser } from '../actions/MemberActions'
import {
  rSendCodeEmail,
  rSendCodeEmailExisting,
  rSignup,
} from '../analytics/AuthAnalytics'

class SignupContainer extends Component {
  state = {
    processed: false,
  }

  componentWillReceiveProps(nextProps) {
    const { processed } = this.state
    if (nextProps && nextProps.sent && !nextProps.error) {
      console.debug('Signed up and email sent!')
      this.props.onSignup()
      rSignup(nextProps)
      return
    }
    if (
      nextProps &&
      nextProps.linked &&
      nextProps.linkedMember &&
      !nextProps.sent &&
      !nextProps.sending &&
      !processed
    ) {
      const { Email } = nextProps.linkedMember
      this.setState({ processed: true })
      console.debug('Sending from linked: ', nextProps)
      this.props.sendCode({ email: Email })
      rSendCodeEmail(nextProps)
      return
    }
    if (
      nextProps &&
      nextProps.existingAccount &&
      !nextProps.sent &&
      !nextProps.sending &&
      !processed
    ) {
      this.setState({ processed: true })
      console.debug('Existing account detected, we sendin: ', nextProps)
      this.props.sendCode({ email: nextProps.existingAccount })
      rSendCodeEmailExisting(nextProps)
      return
    }
  }

  handleSignupClick = ({ email }) => {
    const { verifiedMember } = this.props

    this.props.linkMemberToUser({
      Email: email,
      ...verifiedMember,
    })
  }

  renderError() {
    const { usernameConflict } = this.props
    if (usernameConflict) {
      return (
        <ErrorPage
          details={
            "This email address already has a user assigned to it. If you are trying to register a dependent, you should have access to their ID card(s) under the subscriber's myZERO account"
          }
        />
      )
    }
    return <ErrorPage />
  }

  render() {
    const { error, verifiedMember } = this.props
    if (!verifiedMember) {
      this.props.ifMissingMember()
      return null
    }

    return (
      <div>
        {error ? this.renderError() : null}
        {!error && <Signup onSignupClick={this.handleSignupClick} />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.members.error,
    linked: state.members.linked,
    linkedMember: state.members.linkedMember,
    verifiedMember: state.verify.member,
    sending: state.auth.sendingCode,
    sent: state.auth.codeSent,
    existingAccount: state.members.existingAccount,
    usernameConflict: state.members.usernameConflict,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ linkMemberToUser, sendCode }, dispatch)
}

SignupContainer.propTypes = {
  error: PropTypes.bool,
  linked: PropTypes.bool,
  onSignup: PropTypes.func.isRequired,
  ifMissingMember: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SignupContainer)
