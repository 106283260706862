import React, { Component } from 'react'
import { isEmail } from '../../utils'
import _ from 'lodash'
import './styles.scss'

class signup extends Component {
  state = {
    email: '',
    confirmEmail: '',
    showResult: false,
    showValidations: false,
  }

  componentWillReceiveProps(nextProps) {
    const { result } = nextProps

    if (result && !_.isEqual(result, this.props.result)) {
      let timeout = setTimeout(() => {
        this.setState({ showResult: false })
      }, 1500)
      this.setState({ timeout, showResult: true })
    }
  }

  handleSignupClick = () => {
    // do it
    this.setState({ showValidations: true }) // once we try to signup, validation messages should show.
    if (!this.isValid()) return
    this.props.onSignupClick({ email: this.state.email })
  }

  handleChange = (field) => {
    return (e) => {
      this.validateFields()
      this.setState({ [field]: e.target.value })
    }
  }

  isEmailValid = () => {
    const { email } = this.state

    return email && isEmail(email)
  }

  isConfirmEmailValid = () => {
    const { confirmEmail, email } = this.state

    return confirmEmail && isEmail(confirmEmail) && confirmEmail === email
  }

  getFieldValidations = () => {
    return {
      email: this.isEmailValid(),
      confirmEmail: this.isConfirmEmailValid(),
    }
  }

  validateFields = () => {
    return this.isValid()
  }

  isValid = () => {
    const { email, confirmEmail } = this.getFieldValidations()

    return email && confirmEmail
  }

  render() {
    const { email, confirmEmail, showValidations } = this.state
    const valid = this.getFieldValidations()

    return (
      <div id="signup">
        <h3>Create your account</h3>
        <div className="signup-text-block">
          Provide an email address to link your account to. We will send you an
          email with a code which you can enter to login here.
        </div>
        <div className="fieldset-container">
          <fieldset
            className={!valid.email && showValidations ? 'input-error' : ''}>
            <legend>Email</legend>
            <input
              data-cy="signup-email"
              className="field"
              type="email"
              placeholder="you@someemail.com"
              value={email}
              onChange={this.handleChange('email')}
            />
          </fieldset>
        </div>
        <div className="fieldset-container">
          <fieldset
            className={
              !valid.confirmEmail && showValidations ? 'input-error' : ''
            }>
            <legend>Confirm Email</legend>
            <input
              data-cy="signup-confirm-email"
              className="field"
              type="email"
              placeholder="you@someemail.com"
              value={confirmEmail}
              onChange={this.handleChange('confirmEmail')}
            />
          </fieldset>
        </div>
        <div className="btn-footer">
          <button
            data-cy="signup-email-submit"
            className="button yellow"
            onClick={this.handleSignupClick}>
            Send Code
          </button>
        </div>
      </div>
    )
  }
}

export const Signup = signup
