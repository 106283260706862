import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import compose from 'recompose/compose'
import { SectionHeader } from '../components/Headers'
import { Checkbox } from '../components/Inputs'
import { getUserPolicies, updateUserPolicy } from '../actions/MemberActions'
import { rTogglePolicy } from '../analytics/ProfileAnalytics'
import _ from 'lodash'

class UserPolicyContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const params = queryString.parse(location.search)

    this.state = {
      params: {
        ...params,
      },
      loading: false,
      initialized: false,
    }
  }

  componentDidMount() {
    this.props.getUserPolicies()
  }

  componentWillReceiveProps(nextProps) {
    const { userPolicies } = this.props
    if (!userPolicies && !nextProps.userPolicies) {
      this.props.getUserPolicies()
    }
  }

  toggleUserPolicy = (policyId) => {
    return () => {
      const { userPolicies } = this.props
      let policyToUpdate = _.find(userPolicies, { PolicyID: policyId })

      policyToUpdate = _.assign({}, policyToUpdate, {
        Method: 'Confirmation',
        Accepted: !policyToUpdate.Accepted,
      })
      this.props.updateUserPolicy(policyToUpdate)
      rTogglePolicy(policyToUpdate)
    }
  }

  renderContent = () => {
    const { userPolicies } = this.props
    // Filter privacy policy out of the policies to display on this page
    const PRIVACY_POLICY_TYPE = 1
    const policiesToDisplay = _.filter(
      userPolicies,
      (policy) => policy.TypeID !== PRIVACY_POLICY_TYPE
    )

    return (
      <div class="profile-container">
        <SectionHeader hasFieldset={true}>Terms and Conditions</SectionHeader>
        <div class="test">
          {policiesToDisplay &&
            policiesToDisplay.length > 0 &&
            policiesToDisplay.map((policy) => {
              return (
                <div data-cy={`user-policy-checkbox-id-${policy.PolicyID}`}>
                  <Checkbox
                    label={policy.Content}
                    key={policy.PolicyID}
                    onCheck={this.toggleUserPolicy(policy.PolicyID)}
                    active={policy.Accepted}
                    name={policy.PolicyID}
                  />
                </div>
              )
            })}
        </div>
      </div>
    )
  }

  render() {
    return <div>{this.renderContent()}</div>
  }
}

function mapStateToProps(state) {
  return {
    working: state.members.working,
    error: state.members.error,
    profileInfo: state.members.profile,
    userPolicies: state.members.userPolicies,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getUserPolicies, updateUserPolicy }, dispatch)
}

UserPolicyContainer.propTypes = {
  error: PropTypes.bool,
  profileInfo: PropTypes.object,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserPolicyContainer)
