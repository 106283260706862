import React, { Component } from 'react'
import { View } from '../../components/View'
import './style.scss'

class notFound extends Component {
  render() {
    return (
      <View id="not-found" containerClasses="not-found">
        <h3>Yo, 404</h3>
        <p>
          Zero can help you get the care you need, but cannot help you find this
          page that does not exist.
        </p>
        <p>
          If you think you have reached this page in error, please{' '}
          <a href="https://zero.health/contact-us">contact us</a> or{' '}
          <a href="tel:18558160001">call</a> for assistance.
        </p>
      </View>
    )
  }
}

export const NotFound = notFound
