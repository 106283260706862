import * as firehose from '../services/firehose'

export function rVerifySuccess(data) {
  firehose.put({
    data,
    event: 'walkthrough.verifySuccess',
    descr: 'User has successfully verified their identity',
  })
}

export function rConfirmSuccess(data) {
  firehose.put({
    data,
    event: 'walkthrough.confirmSuccess',
    descr: 'User has confirmed their identity',
  })
}

export function rSignupSuccess(data) {
  firehose.put({
    data,
    event: 'walkthrough.signupSuccess',
    descr: 'User has successfully completed the signup',
  })
}

export function rGoToVerify(data) {
  firehose.put({
    data,
    event: 'walkthrough.goToVerify',
    descr: 'User is skipping the walkthrough to go straight to verification',
  })
}

export function rReVerify(data) {
  firehose.put({
    data,
    event: 'walkthrough.reVerify',
    descr:
      'We couldnt find a member for the initial verification inputs, so return to verify screen',
  })
}

export function rFinish(data) {
  firehose.put({
    data,
    event: 'walkthrough.finish',
    descr: 'User has finished the walkthrough',
  })
}

export function rGoToLogin(data) {
  firehose.put({
    data,
    event: 'walkthrough.goToLogin',
    descr: 'User has left the walkthrough to login',
  })
}

export function rLoadFromQS(data) {
  firehose.put({
    data,
    event: 'walkthrough.loadFromQS',
    descr: 'User is navigating with params on the query string',
  })
}

export function rNavigate(data) {
  firehose.put({
    data,
    event: 'walkthrough.navigate',
    descr: 'User is navigating in the  walkthrough',
  })
}
