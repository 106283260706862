import * as firehose from '../services/firehose'

export function rClickHome() {
  firehose.put({
    data: {},
    event: 'menu.clickHome',
    descr: '',
  })
}

export function rClickIDCardLoggedIn() {
  firehose.put({
    data: {},
    event: 'menu.clickIDCardLoggedIn',
    descr: '',
  })
}

export function rClickIDCardNotLoggedIn() {
  firehose.put({
    data: {},
    event: 'menu.clickIDCardNotLoggedIn',
    descr: '',
  })
}

export function rClickSearch() {
  firehose.put({
    data: {},
    event: 'menu.clickSearch',
    descr: '',
  })
}

export function rClickAccountLoggedIn() {
  firehose.put({
    data: {},
    event: 'menu.clickAccountLoggedIn',
    descr: '',
  })
}

export function rClickAccountNotLoggedIn() {
  firehose.put({
    data: {},
    event: 'menu.clickAccountNotLoggedIn',
    descr: '',
  })
}

export function rClickLogo() {
  firehose.put({
    data: {},
    event: 'menu.clickLogo',
    descr: '',
  })
}
