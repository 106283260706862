import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import compose from 'recompose/compose'
import {
  getUserPolicies,
  updateUserPolicy,
  getProfileInfo,
  getIdCards,
} from '../actions/MemberActions'
import { getUserInfo } from '../actions/AuthActions'
import { Landing } from '../views/Landing'
import { rConfirmProfile, rLogout } from '../analytics/LandingAnalytics'
import { PrivacyPolicyContainer } from '../containers/PrivacyPolicyContainer'
import * as api from '../services/api'

interface Props {
  initStep: number
  firstVisit: boolean
  style: any
  firstName?: string
  isLoggedIn(): boolean
  step?: number
  getUserInfo(): void
  getProfileInfo(): void
  getUserPolicies(): void
  getIdCards(): void
  working: boolean
  confirmed: boolean
  gettingProfileInfo: boolean
  features: any
  userPolicies: any[]
  user: any
  userFetched: boolean
}

// Log the user out and record the logout
const logout = () => {
  rLogout()
  api.logout()
}

const LandingContainer = (props: Props) => {
  const {
    isLoggedIn,
    getUserInfo,
    getProfileInfo,
    getUserPolicies,
    getIdCards,
  } = props

  useEffect(() => {
    if (isLoggedIn()) {
      getUserInfo()
      getProfileInfo()
      getUserPolicies()
      getIdCards()
    }
    // eslint-disable-next-line
  }, [0])

  const { style, step } = props
  const displayAuthText = !props.isLoggedIn()
  const initStep = step !== undefined ? step * 1 : 1

  // Handle Privacy Policy display, if needed
  const { features, confirmed, gettingProfileInfo, userPolicies } = props

  // Pull the privacy policy out of the list of policies so we can use it to conditionally render if accepted
  const PRIVACY_POLICY_TYPE = 1
  const privacyPolArray = _.filter(userPolicies, {
    TypeID: PRIVACY_POLICY_TYPE,
  })
  const privacyPolicy = privacyPolArray[0]

  // Trigger confirmation redirect if needed
  if (features && features.confirm === true) {
    if (
      confirmed !== undefined &&
      !confirmed &&
      !gettingProfileInfo &&
      privacyPolicy &&
      privacyPolicy.Accepted
    ) {
      rConfirmProfile()
      return <Redirect to="/confirm_profile" />
    }
  }

  return (
    <>
      <Landing
        style={style}
        step={initStep}
        displayAuthText={displayAuthText}
        firstName={props.firstName}
        userFinishedLoading={props.userFetched}
        isLoggedIn={isLoggedIn()}
      />
      {isLoggedIn() && <PrivacyPolicyContainer onDecline={logout} />}
    </>
  )
}

function mapStateToProps(state: any) {
  return {
    firstName:
      state.members && state.members.idCards && state.members.idCards.FirstName,
    working: state.global.working,
    confirmed: state.members.confirmed,
    gettingProfileInfo: state.members.gettingProfileInfo,
    features: state.config.features,
    userPolicies: state.members.userPolicies,
    user: state.auth.user || {},
    userFetched: state.auth.userFetched,
  }
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      getUserPolicies,
      updateUserPolicy,
      getUserInfo,
      getProfileInfo,
      getIdCards,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LandingContainer)
