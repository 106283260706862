import * as api from '../services/api'
import { isWorking } from './GlobalActions'
export const LINK_MEMBER_SUCCESS = 'LINK_MEMBER_SUCCESS'
export const LINKING_MEMBER = 'LINKING_MEMBER'
export const LINK_MEMBER_ERROR = 'LINK_MEMBER_ERROR'
export const GET_ID_CARDS_SUCCESS = 'GET_ID_CARDS_SUCCESS'
export const GETTING_ID_CARDS = 'GETTING_ID_CARDS'
export const GET_ID_CARDS_ERROR = 'GET_ID_CARDS_ERROR'
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS'
export const GETTING_PROFILE_INFO = 'GETTING_PROFILE_INFO'
export const GET_PROFILE_INFO_ERROR = 'GET_PROFILE_INFO_ERROR'
export const SAVE_PROFILE_INFO_SUCCESS = 'SAVE_PROFILE_INFO_SUCCESS'
export const SAVING_PROFILE_INFO = 'SAVING_PROFILE_INFO'
export const SAVE_PROFILE_INFO_ERROR = 'SAVE_PROFILE_INFO_ERROR'
export const CONFIRM_PROFILE_INFO_SUCCESS = 'CONFIRM_PROFILE_INFO_SUCCESS'
export const CONFIRMING_PROFILE_INFO = 'CONFIRMING_PROFILE_INFO'
export const CONFIRM_PROFILE_INFO_ERROR = 'CONFIRM_PROFILE_INFO_ERROR'
export const GET_USER_POLICIES_SUCCESS = 'GET_USER_POLICIES_SUCCESS'
export const GETTING_USER_POLICIES = 'GETTING_USER_POLICIES'
export const GET_USER_POLICIES_ERROR = 'GET_USER_POLICIES_ERROR'
export const UPDATE_USER_POLICY_SUCCESS = 'UPDATE_USER_POLICY_SUCCESS'
export const UPDATING_USER_POLICY = 'UPDATING_USER_POLICY'
export const UPDATE_USER_POLICY_ERROR = 'UPDATE_USER_POLICY_ERROR'

export const getIdCards = () => {
  return (dispatch) => {
    dispatch(gettingIdCards(true))
    dispatch(isWorking(true))
    api
      .get('/my/id_cards')
      .then((result) => {
        const { Data } = result
        if (Data) {
          dispatch(getIdCardsSuccess(result))
          dispatch(isWorking(false))
        } else {
          dispatch(getIdCardsError(result.Error))
          dispatch(isWorking(false))
          console.error('Error fetching id cards: ', result)
        }
      })
      .catch((err) => dispatch(getIdCardsError(err)))
  }
}

export const getIdCardsSuccess = (res) => {
  return {
    type: GET_ID_CARDS_SUCCESS,
    payload: res,
  }
}

export const gettingIdCards = (working) => {
  return {
    type: GETTING_ID_CARDS,
    working,
  }
}

export const getIdCardsError = (err) => {
  return {
    type: GET_ID_CARDS_ERROR,
    error: err,
  }
}

export const linkMemberToUser = (params) => {
  return (dispatch) => {
    dispatch(linkingMemberToUser(params.Email))
    dispatch(isWorking(true))
    api
      .put('/public/link_identity_to_user', params)
      .then((result) => {
        const { Data, Error } = result
        if (Data && !Error) {
          dispatch(linkMemberToUserSuccess(result))
          dispatch(isWorking(false))
        } else {
          dispatch(linkMemberToUserError(result))
          dispatch(isWorking(false))
          console.error('Error linking member to user: ', params)
        }
      })
      .catch((err) => dispatch(linkMemberToUserError(err)))
  }
}

export const linkMemberToUserSuccess = (res) => {
  return {
    type: LINK_MEMBER_SUCCESS,
    payload: res,
  }
}

export const linkingMemberToUser = (linkingEmail) => {
  return {
    type: LINKING_MEMBER,
    payload: { Email: linkingEmail },
  }
}

export const linkMemberToUserError = (err) => {
  return {
    type: LINK_MEMBER_ERROR,
    error: err,
  }
}

export const getProfileInfo = (params) => {
  return (dispatch) => {
    dispatch(gettingProfileInfo(true))
    dispatch(isWorking(true))
    api
      .get('/my/profile')
      .then((result) => {
        const { Data } = result
        if (Data) {
          dispatch(getProfileInfoSuccess(result))
          dispatch(isWorking(false))
        } else {
          dispatch(getProfileInfoError(result.Error))
          dispatch(isWorking(false))
          console.error('Error fetching profile info: ', result)
        }
      })
      .catch((err) => dispatch(getProfileInfoError(err)))
  }
}

export const getProfileInfoSuccess = (res) => {
  return {
    type: GET_PROFILE_INFO_SUCCESS,
    payload: res,
  }
}

export const gettingProfileInfo = (working) => {
  return {
    type: GETTING_PROFILE_INFO,
    working,
  }
}

export const getProfileInfoError = (err) => {
  return {
    type: GET_PROFILE_INFO_ERROR,
    error: err,
  }
}

export const saveProfileInfo = (payload) => {
  return (dispatch) => {
    dispatch(savingProfileInfo(true))
    dispatch(isWorking(true))
    api
      .put('/my/profile', payload)
      .then((result) => {
        const { Data } = result
        if (Data) {
          dispatch(saveProfileInfoSuccess(result))
          dispatch(isWorking(false))
        } else {
          dispatch(saveProfileInfoError(result.Error))
          dispatch(isWorking(false))
          console.error('Error saving profile info: ', result)
        }
      })
      .catch((err) => dispatch(saveProfileInfoError(err)))
  }
}

export const saveProfileInfoSuccess = (res) => {
  return {
    type: SAVE_PROFILE_INFO_SUCCESS,
    payload: res,
  }
}

export const savingProfileInfo = (working) => {
  return {
    type: SAVING_PROFILE_INFO,
    working,
  }
}

export const saveProfileInfoError = (err) => {
  return {
    type: SAVE_PROFILE_INFO_ERROR,
    error: err,
  }
}

export const confirmProfileInfo = (payload) => {
  return (dispatch) => {
    dispatch(confirmingProfileInfo(true))
    dispatch(isWorking(true))
    payload.Confirmed = true
    api
      .put('/my/profile', payload)
      .then((result) => {
        const { Data } = result
        if (Data) {
          dispatch(confirmProfileInfoSuccess(result))
          dispatch(isWorking(false))
        } else {
          dispatch(confirmProfileInfoError(result.Error))
          dispatch(isWorking(false))
          console.error('Error confirming profile info: ', result)
        }
      })
      .catch((err) => dispatch(confirmProfileInfoError(err)))
  }
}

export const confirmProfileInfoSuccess = (res) => {
  return {
    type: CONFIRM_PROFILE_INFO_SUCCESS,
    payload: res,
  }
}

export const confirmingProfileInfo = (working) => {
  return {
    type: CONFIRMING_PROFILE_INFO,
    working,
  }
}

export const confirmProfileInfoError = (err) => {
  return {
    type: CONFIRM_PROFILE_INFO_ERROR,
    error: err,
  }
}

export const updateUserPolicy = (payload) => {
  return (dispatch) => {
    dispatch(updatingUserPolicy(true))
    dispatch(isWorking(true))
    api
      .put('/my/policy', payload)
      .then((result) => {
        const { Data } = result
        if (Data) {
          dispatch(updateUserPolicySuccess(result))
          dispatch(isWorking(false))
        } else {
          dispatch(updateUserPolicyError(result.Error))
          dispatch(isWorking(false))
          console.error('Error updating user policy: ', result)
        }
      })
      .catch((err) => dispatch(updateUserPolicyError(err)))
  }
}

export const updateUserPolicySuccess = (res) => {
  return {
    type: UPDATE_USER_POLICY_SUCCESS,
    payload: res,
  }
}

export const updatingUserPolicy = (working) => {
  return {
    type: UPDATING_USER_POLICY,
    working,
  }
}

export const updateUserPolicyError = (err) => {
  return {
    type: UPDATE_USER_POLICY_ERROR,
    error: err,
  }
}

export const getUserPolicies = () => {
  return (dispatch) => {
    dispatch(gettingUserPolicies(true))
    dispatch(isWorking(true))
    api
      .get('/my/policies')
      .then((result) => {
        const { Data } = result
        if (Data) {
          dispatch(getUserPoliciesSuccess(result))
          dispatch(isWorking(false))
        } else {
          dispatch(getUserPoliciesError(result.Error))
          dispatch(isWorking(false))
          console.error('Error getting user policies: ', result)
        }
      })
      .catch((err) => dispatch(getUserPoliciesError(err)))
  }
}

export const getUserPoliciesSuccess = (res) => {
  return {
    type: GET_USER_POLICIES_SUCCESS,
    payload: res,
  }
}

export const gettingUserPolicies = (working) => {
  return {
    type: GETTING_USER_POLICIES,
    working,
  }
}

export const getUserPoliciesError = (err) => {
  return {
    type: GET_USER_POLICIES_ERROR,
    error: err,
  }
}
