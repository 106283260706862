import axios from 'axios'
import auth0 from 'auth0-js'
import decode from 'jwt-decode'
import { getItem, setItem, removeItem } from './local'

const BASE_URL = process.env.REACT_APP_BASE_URL
const axiosClient = axios.create({
  baseUrl: BASE_URL,
})

// TODO: this needs to be updated
axiosClient.interceptors.response.use(
  function (response) {
    return apiResponse(response)
  },
  function (error) {
    if (error && error.response) {
      if (error.response.status === 401) return

      console.error('Detailed error for debugging: ', error.response.data)
      return {
        ...error.response.data,
        error: true,
      }
    }
  }
)

function apiResponse(res) {
  const { data, ...resNoData } = res
  return {
    Data: data.Data || data || null,
    Meta: data.Meta || null,
    ...resNoData,
  }
}

function queryParams(params) {
  return Object.keys(params)
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
}

/* new methods: use these - see actions/index.js */

export function get(url, params) {
  let requestUrl = BASE_URL + url

  if (params) {
    requestUrl += '?' + queryParams(params)
  }

  return axiosClient.get(requestUrl, getBaseConfig())
}

export function getBinary(url) {
  const requestUrl = BASE_URL + url
  return axiosClient.get(
    requestUrl,
    Object.assign({}, getBaseConfig(), {
      responseType: 'arraybuffer',
    })
  )
}

export function patch(url, body) {
  const requestUrl = BASE_URL + url

  return axiosClient.patch(requestUrl, body, getBaseConfig())
}

export function post(url, body) {
  const requestUrl = BASE_URL + url

  return axiosClient.post(requestUrl, body, getBaseConfig())
}

export function put(url, body) {
  const requestUrl = BASE_URL + url

  return axiosClient.put(requestUrl, body, getBaseConfig())
}

export function del(url) {
  const requestUrl = BASE_URL + url

  return axiosClient.delete(requestUrl, getBaseConfig())
}

function getBaseConfig() {
  return {
    headers: getHeaders(),
  }
}

function getHeaders() {
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${getToken()}`,
  }
}

// everything below this could move to an auth service if we want
export function getToken() {
  return getItem('id_token')
}

export function loggedIn() {
  // Checks if there is a saved token and it's still valid
  const token = getToken()

  return !!token && !isTokenExpired(token)
}

const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
  responseType: 'token id_token',
  scope: 'openid profile email',
  prompt: 'none',
})

export function logout() {
  removeItem('id_token')
  webAuth.logout({
    returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
  })
}

export function resetVisited() {
  removeItem('visited')
}

export function parseHash(params, cb) {
  webAuth.parseHash(params.hash, (err, res) => {
    if (err) return cb(null, err)
    console.debug('Parsed hash success: ', res)

    setItem('visited', true)
    setItem('id_token', res.idToken)
    cb(res, null)
  })
}

function executePasswordlessStart(params, cb) {
  setItem('loginEmail', params.email)
  webAuth.passwordlessStart(
    {
      connection: 'email',
      send: params.send,
      email: params.email,
    },
    (err, res) => {
      if (err) {
        console.debug(`Problem sending ${params.send} to: `, params.email, err)
        cb(res, err)
        return
      }
      setItem('visited', true)
      cb(res, err)
    }
  )
}

export function sendMagicLinkEmail(params, cb) {
  return executePasswordlessStart(
    {
      ...params,
      send: 'link',
    },
    cb
  )
}

export function sendCodeEmail(params, cb) {
  return executePasswordlessStart(
    {
      ...params,
      send: 'code',
    },
    cb
  )
}

export function loginWithCode(params, cb) {
  const loginEmail = getItem('loginEmail')
  webAuth.passwordlessLogin(
    {
      connection: 'email',
      verificationCode: params.code,
      email: loginEmail,
      callbackURL: process.env.REACT_APP_AUTH0_CALLBACK_URL,
    },
    (err, res) => {
      if (err) {
        console.debug(
          `Problem verifying code ${params.code} to: `,
          loginEmail,
          err
        )
        cb(res, err)
        return
      }
      setItem('visited', true)
      cb(res, err)
    }
  )
}

/**
 * Persist the redirect route to local stroage for the app
 * to redirect too after login.
 */
export const persistRedirectRoute = (route) => {
  setItem('redirectRoute', route)
}

/**
 * Get the redirect route from local storage
 */
export const getPersistedRedirectRoute = () => {
  const test = getItem('redirectRoute')
  console.debug('Got here', test)
  return test
}

export var url = BASE_URL

function getTokenExpirationDate(token) {
  const decoded = decode(token)
  if (!decoded.exp) {
    return null
  }

  const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp)
  return date
}

function isTokenExpired(token) {
  const date = getTokenExpirationDate(token)
  if (date === null) {
    return false
  }
  return !(date.valueOf() > new Date().valueOf())
}
