import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import compose from 'recompose/compose'
import { Verify } from '../components/Verify'
import { ErrorPage } from '../views/ErrorPage'

import { clearVerifyState, verifyMember } from '../actions/VerifyActions'

class VerifyContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const params = queryString.parse(location.search)

    this.state = {
      params: {
        ...params,
      },
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.verified) {
      this.props.onSuccess()
    }
  }

  handleVerify = (params) => {
    this.props.verifyMember(params)
  }

  resetState = () => {
    this.props.clearVerifyState()
  }

  render() {
    const { error, working, isMinor } = this.props

    const details = isMinor ? (
      <p>
        Members under the age of 13 can access their ID Card through the
        subscriber's account. If you have not set up the subscriber's account,
        you can do that{' '}
        <Link to={this.props.location} onClick={this.resetState}>
          here
        </Link>
        .
      </p>
    ) : (
      <p>
        There was a problem verifying your identity. If you think you might have
        entered some information incorrectly, please{' '}
        <Link to={this.props.location} onClick={this.resetState}>
          try again
        </Link>
        . Otherwise please contact us!
      </p>
    )
    return (
      <div>
        {error ? (
          <ErrorPage details={details} />
        ) : (
          <Verify onVerifyClick={this.handleVerify} working={working} />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.verify.error,
    isMinor: state.verify.isMinor,
    member: state.verify.member,
    verified: state.verify.verified,
    working: state.verify.working,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ verifyMember, clearVerifyState }, dispatch)
}

VerifyContainer.propTypes = {
  error: PropTypes.bool,
  isMinor: PropTypes.bool,
  result: PropTypes.object,
  clearVerifyState: PropTypes.func.isRequired,
  verifyMember: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VerifyContainer)
