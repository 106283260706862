import React from 'react'
import {
  FooterContainer,
  FooterChildren,
  BackButton,
  NextButton,
} from './styledComponents'
/**
 * Footer that allows a user to advance
 * or go backwards in a flow
 */

// Types
interface Props {
  onNextPress(): void
  onBackPress(): void
}

export const NavFooter = (props: Props) => {
  // Conditionally render the back button if the correct prop comes through
  const backButton = props.onBackPress ? (
    <BackButton
      onClick={() => props.onBackPress()}
      data-cy="nav-footer-back-button">
      Back
    </BackButton>
  ) : null
  const nextButton = props.onNextPress ? (
    <NextButton
      onClick={() => props.onNextPress()}
      data-cy="nav-footer-next-button">
      Next
    </NextButton>
  ) : null

  return (
    <FooterContainer>
      <FooterChildren>
        {backButton}
        {nextButton}
      </FooterChildren>
    </FooterContainer>
  )
}
