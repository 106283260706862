import * as api from '../services/api'
import { download } from '../utils'
import { isWorking } from './GlobalActions'
export const ADD_ID_CARD_TO_WALLET_ERROR = 'ADD_ID_CARD_TO_WALLET_ERROR'
export const ADDING_ID_CARD_TO_WALLET = 'ADD_ID_CARD_TO_WALLET'
export const ADD_ID_CARD_TO_WALLET_SUCCESS = 'ADD_ID_CARD_TO_WALLET_SUCCESS'

export const addIDCardToWallet = (params) => {
  return (dispatch) => {
    console.debug('Adding id card to wallet: ', params)
    dispatch(addingIDCardToWallet(true))
    dispatch(isWorking(true))

    return api
      .getBinary(`/passkit/member_id_card/${params.employerMemberID}`)
      .then((res) => {
        if (res.error) {
          throw res
        }
        const url = URL.createObjectURL(
          new Blob([res.Data], { type: 'application/vnd.apple.pkpass' })
        )
        download(url, 'pass')
        return url
      })
      .catch((err) => {
        console.warn('PasskitError: ', err)
        alert(
          'We apologize: adding your Member ID Card to Apple Wallet is not working at this time. If you see this, we would love to hear from you.'
        )
        throw err
      })
  }
}

export const addIDCardToWalletSuccess = (res) => {
  return {
    type: ADD_ID_CARD_TO_WALLET_SUCCESS,
    payload: res,
  }
}

export const addingIDCardToWallet = (working) => {
  return {
    type: ADDING_ID_CARD_TO_WALLET,
    working,
  }
}

export const addIDCardToWalletError = (err) => {
  return {
    type: ADD_ID_CARD_TO_WALLET_ERROR,
    error: err,
  }
}
