import {
  CLEAR_VERIFY_STATE,
  VERIFY_MEMBER_ERROR,
  VERIFY_MEMBER_SUCCESS,
  VERIFYING_MEMBER,
} from '../actions/VerifyActions'

// eslint-disable-next-line
export default function (state = null, action) {
  switch (action.type) {
    case VERIFYING_MEMBER:
      return {
        ...state,
        verified: false,
        verifying: true,
      }
    case VERIFY_MEMBER_ERROR:
      return {
        ...state,
        error: true,
        isMinor: action.isMinor,
      }
    case VERIFY_MEMBER_SUCCESS:
      return {
        ...state,
        verified: true,
        member: action.payload.Data,
      }
    case CLEAR_VERIFY_STATE:
      return {
        ...state,
        error: false,
        verifying: false,
        member: null,
      }
    default:
      return {
        ...state,
      }
  }
}
