import React, { useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import styled from 'styled-components'
import {
  updateMenuPosition,
  updateLoginRedirectRoute,
} from '../../actions/GlobalActions'
import { COLORS } from '../../utils/colors'
import { FeatureGateDialog } from '../FeatureGateDialog'
import {
  HomeIcon,
  CardIcon,
  SearchIcon,
  CardHighlightedIcon,
  HomeHighlightedIcon,
  SearchHighlightedIcon,
} from '../../assets/icons'
import {
  rClickHome,
  rClickIDCardLoggedIn,
  rClickIDCardNotLoggedIn,
  rClickSearch,
} from '../../analytics/MenuAnalytics'

// Styled components
const CustomBottomNavigation = styled(BottomNavigation)`
  width: 100%;
  position: fixed;
  z-index: 1;
  bottom: 0;
  border-top: 1px solid ${COLORS.BW.CLOUDS};

  button.MuiBottomNavigationAction-root {
    color: ${COLORS.BW.GUNMETAL};
  }

  button.MuiBottomNavigationAction-root.Mui-selected {
    color: ${COLORS.MEMBERS.OCEAN};
  }

  .MuiBottomNavigationAction-label {
    font-family: 'Raleway';
  }
`

// Main Component Code
export const TabNavigationComponent = (props: any) => {
  const history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false)

  const iconStyle = { width: 25, height: 25 }
  // Select Icons To Render
  const home = !props.menuPosition ? (
    <HomeHighlightedIcon style={iconStyle} />
  ) : (
    <HomeIcon style={iconStyle} />
  )
  const card =
    props.menuPosition === 1 ? (
      <CardHighlightedIcon style={iconStyle} />
    ) : (
      <CardIcon style={iconStyle} />
    )
  const search =
    props.menuPosition === 2 ? (
      <SearchHighlightedIcon style={iconStyle} />
    ) : (
      <SearchIcon style={iconStyle} />
    )

  return (
    <>
      <CustomBottomNavigation
        value={props.menuPosition ? props.menuPosition : 0}
        onChange={(event, newValue) => {
          // Update menu position in redux
          props.updateMenuPosition(newValue)
          // Navigate to the correct page based on selection
          switch (newValue) {
            case 0:
              history.push('/')
              rClickHome()
              break
            case 1:
              props.updateLoginRedirectRoute('/idcard')
              // Navigate to the id card page if possible if not gate the feature
              if (props.isLoggedIn) {
                history.push(`/member/${props.idCards.MemberID}/idcard`)
                rClickIDCardLoggedIn()
              } else {
                setDialogOpen(true)
                rClickIDCardNotLoggedIn()
              }
              break
            case 2:
              history.push('/findcare')
              rClickSearch()
              break
            default:
              break
          }
        }}
        showLabels
        id="tab-navigation">
        <BottomNavigationAction
          label="Home"
          icon={home}
          value={0}
          data-cy="home-bottom-tab"
        />
        <BottomNavigationAction
          label="ID Card"
          icon={card}
          value={1}
          data-cy="id-card-bottom-tab"
        />
        <BottomNavigationAction
          label="Find Care"
          icon={search}
          value={2}
          data-cy="find-care-bottom-tab"
        />
      </CustomBottomNavigation>
      <FeatureGateDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      />
    </>
  )
}

// Redux configuration
function mapStateToProps(state: any) {
  return {
    menuPosition: state.global.menuPosition,
    idCards: state.members.idCards,
    loginRedirectRoute: state.global.loginRedirectRoute,
    isLoggedIn: state.auth ? !!state.auth.user : null,
  }
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    { updateMenuPosition, updateLoginRedirectRoute },
    dispatch
  )
}

const TabNavigation = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TabNavigationComponent)

export { TabNavigation }
