import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmail } from '../../utils'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import './style.scss'

export class Login extends Component {
  state = {
    email: '',
    last4Ssn: '',
    showValidations: false,
    valid: {
      email: false,
      last4Ssn: false,
    },
  }

  handleLogin = (e) => {
    const { last4Ssn, email } = this.state

    this.setState({ showValidations: true })
    if (!this.isValid()) return
    this.props.loginHandler({ last4Ssn, email })
  }

  signup = () => {
    this.props.signupHandler()
  }

  handleSsnChange = (e) => {
    const { value } = e.target
    if (value && value.length > 4) return // max length of 4
    if (isNaN(Number(value))) return // ignore non numeric

    this.setState({ last4Ssn: value })
  }

  isEmailValid = () => {
    const { email } = this.state

    return email && isEmail(email)
  }

  isLast4SsnValid = () => {
    const { last4Ssn } = this.state

    return last4Ssn && _.isNumber(last4Ssn * 1) && last4Ssn.length === 4
  }

  getFieldValidations = () => {
    return {
      email: this.isEmailValid(),
      last4Ssn: this.isLast4SsnValid(),
    }
  }

  isValid = () => {
    const { email, last4Ssn } = this.getFieldValidations()

    return email && last4Ssn
  }

  render() {
    const { showValidations, email, last4Ssn } = this.state
    const valid = this.getFieldValidations()

    // @CONTEXT - we do not use the last4ssn to do any validation on the server side. If the user has an email address with an account, they will receive an email to login. The last4ssn exist here for a placebo effect, making this look more like a typical login screen.

    return (
      <form className="login-form">
        <div className="fieldset-container">
          <fieldset
            className={
              !valid.email && showValidations ? 'input-error' : undefined
            }>
            <legend>Email</legend>
            <input
              type="email"
              placeholder="user@name.com"
              value={email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </fieldset>
        </div>
        <div className="fieldset-container">
          <fieldset
            className={
              !valid.last4Ssn && showValidations ? 'input-error' : undefined
            }>
            <legend>Last 4 SSN</legend>
            <input
              type="password"
              placeholder="****"
              value={last4Ssn}
              onChange={this.handleSsnChange}
            />
          </fieldset>
        </div>
        <div className="text-center">
          <button
            onClick={this.handleLogin}
            type="button"
            className="button yellow">
            Send Code
          </button>
        </div>
        <div className="login-footer">
          <Link to="/code">Already have a code?</Link>
          <span className="divider">|</span>
          <span onClick={this.signup} className="as-link">
            New Here? Sign up.
          </span>
          <span className="divider">|</span>
          <Link to="/support">Need Help?</Link>
        </div>
      </form>
    )
  }
}
Login.defaultProps = {
  loginHandler() {
    /* noop */
  },
  signupHandler() {},
}
Login.propTypes = {
  loginHandler: PropTypes.func.isRequired,
  signupHandler: PropTypes.func.isRequired,
}
