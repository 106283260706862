import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import HelpIcon from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'
import compose from 'recompose/compose'
import Profile from '../models/Profile'
import { SectionHeader } from '../components/Headers'
import {
  TextField,
  DateField,
  EmailField,
  PhoneField,
} from '../components/Fields'
import { ErrorPage } from '../views/ErrorPage'
import {
  getProfileInfo,
  saveProfileInfo,
  confirmProfileInfo,
} from '../actions/MemberActions'
import { getUserInfo } from '../actions/AuthActions'
import UserPolicyContainer from './UserPolicyContainer'
import _ from 'lodash'
import moment from 'moment'
import {
  rSaveProfile,
  rConfirmProfile,
  rCancelEdit,
} from '../analytics/ProfileAnalytics'
import * as api from '../services/api' // todo: remove or make better after beta
import { COLORS } from '../utils/colors'

class ProfileContainer extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const params = queryString.parse(location.search)
    const init = _.assign({}, Profile.editFields)

    this.state = {
      params: {
        ...params,
      },
      loading: false,
      editing: false,
      fields: init,
      initialFields: {},
      initialized: false,
      showEdit: props.showEdit,
      showConfirm: props.showConfirm,
    }
  }

  componentDidMount() {
    this.props.getUserInfo()
  }

  componentWillReceiveProps(nextProps) {
    const { profileInfo, confirm, error, gettingProfileInfo } = this.props
    const { initialized } = this.state
    if (
      !profileInfo &&
      !nextProps.profileInfo &&
      !error &&
      !gettingProfileInfo
    ) {
      this.props.getProfileInfo()
    }
    if (profileInfo && !_.isEmpty(profileInfo) && !initialized) {
      const { fields } = this.state
      const populatedFields = _.assign({}, fields, profileInfo)
      console.debug(
        'Populating fields with fetched profile data: ',
        populatedFields
      )
      populatedFields.DateOfBirth = moment(populatedFields.DateOfBirth)
        .utc()
        .format('MM/DD/YYYY')
      this.setState({
        initialized: true,
        fields: populatedFields,
        initialFields: populatedFields,
      })
    }
    if (confirm) {
      this.setState({ editing: true, showConfirm: true, showEdit: false })
    }
  }

  logout = () => {
    api.logout()
  }

  handleFieldChange = ({ name, value }) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value,
      },
    })
  }

  presaveAndGeneratePayload = () => {
    const { fields } = this.state
    this.setState({ editing: false, initialFields: fields })
    return _.assign({}, fields, {
      DateOfBirth: moment(fields.DateOfBirth).utc().format('YYYY-MM-DD'),
    })
  }

  handleSave = () => {
    // Check if the fields have changed if not then cancel
    if (this.isSavable()) {
      const payload = this.presaveAndGeneratePayload()
      this.props.saveProfileInfo(payload)
      rSaveProfile()
    } else {
      this.handleCancel()
    }
  }

  handleConfirm = () => {
    console.debug('Got here', 1)
    const payload = this.presaveAndGeneratePayload()
    console.debug('Got here', 2, payload)
    this.props.confirmProfileInfo(payload)
    rConfirmProfile()
  }

  handleCancel = () => {
    const { initialFields } = this.state
    this.setState({ fields: initialFields, editing: false })
    rCancelEdit()
  }

  /**
   * Determine if the fields have been changed by the user
   */
  isSavable = () => {
    const { initialFields, fields } = this.state
    return !_.isEqual(fields, initialFields)
  }

  renderContent = () => {
    const { profileInfo } = this.props
    if (!profileInfo) return <div />

    return this.renderViewContent()
  }

  renderViewContent = () => {
    const { editing, fields, showEdit, showConfirm } = this.state
    const { saveError, saving, confirmed, user } = this.props

    return (
      <div class="profile-container">
        <div className="action-button-container">
          <SectionHeader hasFieldset={true}>
            User Information
            <Tooltip title="Info used to identify your account in the our system.">
              <HelpIcon
                style={{
                  fontSize: 18,
                  color: COLORS.BW.GUNMETAL,
                  paddingLeft: 2,
                }}
              />
            </Tooltip>
          </SectionHeader>
          {showEdit && (
            <div>
              {!editing && (
                <button
                  className="button clear"
                  onClick={() => {
                    this.setState({ editing: true })
                  }}>
                  Edit <i title="Edit" className="icon-edit"></i>
                </button>
              )}
              {editing && (
                <div>
                  <button className="button clear" onClick={this.handleCancel}>
                    Cancel
                  </button>
                  <button className="button yellow" onClick={this.handleSave}>
                    Save
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          {user && (
            <TextField
              label="Email"
              handleChange={this.handleFieldChange}
              value={user.Email}
              name="Email"
              type="email"
              viewOnly
            />
          )}
          <TextField
            label="First Name"
            handleChange={this.handleFieldChange}
            value={fields.FirstName}
            name="FirstName"
            placeholder="Jane"
            viewOnly={!editing}
          />
          <TextField
            label="Last Name"
            handleChange={this.handleFieldChange}
            value={fields.LastName}
            name="LastName"
            placeholder="Doe"
            viewOnly={!editing}
          />
        </div>
        <SectionHeader hasFieldset={true}>
          Member Information
          <Tooltip title="Info used when connecting you to providers">
            <HelpIcon
              style={{
                fontSize: 18,
                color: COLORS.BW.GUNMETAL,
                paddingLeft: 2,
              }}
            />
          </Tooltip>
        </SectionHeader>
        <div
          style={{ paddingBottom: 10, marginTop: -10 }}
          className="text-left">
          This data was received from your employer. In case of error, please
          talk to the person in charge of medical benefits at your employer.
        </div>
        <div>
          <DateField
            data-cy="profile-dob"
            label="Date of Birth"
            handleChange={this.handleFieldChange}
            name="DateOfBirth"
            value={fields.DateOfBirth}
            viewOnly={!editing}
          />
          <PhoneField
            data-cy="profile-phone"
            label="Contact Phone"
            handleChange={this.handleFieldChange}
            name="ContactPhone"
            value={fields.ContactPhone}
            viewOnly={!editing}
          />
          <EmailField
            data-cy="profile-email"
            label="Contact Email"
            handleChange={this.handleFieldChange}
            name="ContactEmail"
            value={fields.ContactEmail}
            viewOnly={!editing}
          />
          <TextField
            data-cy="profile-address"
            label="Address"
            handleChange={this.handleFieldChange}
            value={fields.Address1}
            name="Address1"
            placeholder="66 S. Logan St."
            viewOnly={!editing}
          />
          <TextField
            data-cy="profile-address-2"
            label="Address Line 2"
            handleChange={this.handleFieldChange}
            value={fields.Address2}
            name="Address2"
            placeholder="Ex. Apt #1"
            viewOnly={!editing}
          />
          <TextField
            data-cy="profile-city"
            label="City"
            handleChange={this.handleFieldChange}
            value={fields.City}
            placeholder="Denver"
            name="City"
            viewOnly={!editing}
          />
          <TextField
            data-cy="profile-state"
            label="State"
            handleChange={this.handleFieldChange}
            value={fields.State}
            placeholder="CO"
            name="State"
            viewOnly={!editing}
          />
          <TextField
            data-cy="profile-zip"
            label="Zip"
            handleChange={this.handleFieldChange}
            value={fields.Zip}
            placeholder="55555"
            name="Zip"
            viewOnly={!editing}
          />
        </div>
        <UserPolicyContainer confirm={this.props.confirm} />
        <div>
          {showConfirm && (
            <div className="text-center" style={{ padding: '2rem 0' }}>
              {saveError && (
                <span>
                  There was an issue confirming your data, please try again or
                  contact <a href="https://zero.health/contact-us">support</a>
                </span>
              )}
              {confirmed ? (
                <button
                  data-cy="profile-edit-confirm-disabled"
                  className="button yellow"
                  disabled>
                  Confirmed
                </button>
              ) : (
                <button
                  className="button yellow"
                  onClick={this.handleConfirm}
                  disabled={saving}
                  data-cy="profile-edit-confirm">
                  Confirm
                </button>
              )}
            </div>
          )}
        </div>
        <SectionHeader>Other</SectionHeader>
        <div style={{ paddingTop: 10 }} className="text-left">
          If you would like to log in as another member, or a different account,{' '}
          <span onClick={api.logout} className="as-link">
            click here to logout
          </span>
        </div>
      </div>
    )
  }

  render() {
    const { error } = this.props

    return (
      <div>
        {error && (
          <ErrorPage
            details={
              'We had trouble finding your profile information, please try again or contact us if you continue to experience issues.'
            }
          />
        )}
        {this.renderContent()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    working: state.global.working,
    error: state.members.error,
    profileInfo: state.members.profile,
    gettingProfileInfo: state.members.gettingProfileInfo,
    saveError: state.members.saveError,
    confirmed: state.members.confirmed,
    user: state.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getProfileInfo, saveProfileInfo, confirmProfileInfo, getUserInfo },
    dispatch
  )
}

ProfileContainer.propTypes = {
  error: PropTypes.bool,
  profileInfo: PropTypes.object,
  showConfirm: PropTypes.bool,
  showEdit: PropTypes.bool,
}

ProfileContainer.defaultProps = {
  error: false,
  profileInfo: null,
  showConfirm: false,
  showEdit: true,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProfileContainer)
