import * as s3 from '../services/s3'
import { isWorking } from './GlobalActions'
export const GETTING_CONFIG = 'GETTING_CONFIG'
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS'
export const GET_CONFIG_ERROR = 'GET_CONFIG_ERROR'

export const getConfig = () => {
  return (dispatch) => {
    console.debug('Getting app config')
    dispatch(gettingConfig(true))
    dispatch(isWorking(true))
    s3.get('/config.json')
      .then((result) => {
        const { data } = result
        if (data) {
          dispatch(getConfigSuccess(data))
          dispatch(isWorking(false))
          console.debug('Successfully got app config: ', result)
        } else {
          dispatch(getConfigError(result.Error))
          dispatch(isWorking(false))
          console.debug('Error fetching app config: ', result)
        }
      })
      .catch((err) => dispatch(getConfigError(err)))
  }
}

export const getConfigSuccess = (res) => {
  return {
    type: GET_CONFIG_SUCCESS,
    payload: res,
  }
}

export const gettingConfig = (working) => {
  return {
    type: GETTING_CONFIG,
    working,
  }
}

export const getConfigError = (err) => {
  return {
    type: GET_CONFIG_ERROR,
    error: err,
  }
}
