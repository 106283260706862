import {
  ADDING_ID_CARD_TO_WALLET,
  ADD_ID_CARD_TO_WALLET_SUCCESS,
  ADD_ID_CARD_TO_WALLET_ERROR,
} from '../actions/PasskitActions'

// eslint-disable-next-line
export default function (state = null, action) {
  switch (action.type) {
    case ADD_ID_CARD_TO_WALLET_ERROR: {
      return {
        working: isWorking(state),
        addingIDCardToWallet: false,
        error: true,
      }
    }
    case ADD_ID_CARD_TO_WALLET_SUCCESS: {
      return {
        working: isWorking(state),
        addingIDCardToWallet: false,
        error: false,
      }
    }
    case ADDING_ID_CARD_TO_WALLET: {
      return {
        working: isWorking(state),
        addingIDCardToWallet: true,
        error: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

function isWorking(state) {
  return state.addingIDCardToWallet
}
