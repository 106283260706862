import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { PrivacyDialog } from '../components/PrivacyDialog'
import { getUserPolicies, updateUserPolicy } from '../actions/MemberActions'
import { rTogglePolicy } from '../analytics/ProfileAnalytics'
import compose from 'recompose/compose'
import zenDesk from '../utils/zendeskWidget'

/**
 * A container to display the privacy policy.
 *
 * Props
 * - onDecline: a function to run when the user declines the privacy policy
 */
class PrivacyPolicy extends Component {
  state = {
    privacyPolicy: null,
  }

  componentDidMount() {
    this.props.getUserPolicies()
  }

  shouldPrivacyDialogBeOpen = () => {
    const { privacyPolicy } = this.state

    /**
     * If there is a privacy policy parse it to a React component
     * we index it at 0 because there should not be more than one
     * policy at a time. Even if there is we'd want to take the first one.
     */
    if (privacyPolicy && !privacyPolicy.Accepted) {
      // Hide the privacy widget. The settimeout delay assures this happens after the widget is initalized in landing container
      zenDesk().then((sdk) => {
        setTimeout(() => {
          sdk.hide()
        }, 500)
      })

      return true
    }

    return false
  }

  togglePrivacyPolicy = () => {
    let privacyPolicyToUpdate = this.state.privacyPolicy

    if (privacyPolicyToUpdate) {
      privacyPolicyToUpdate = _.assign({}, privacyPolicyToUpdate, {
        Method: 'Confirmation',
        Accepted: true,
      })
      this.props.updateUserPolicy(privacyPolicyToUpdate)
      rTogglePolicy(privacyPolicyToUpdate)

      // Update the privacy policy in local state
      this.setState({
        privacyPolicy: privacyPolicyToUpdate,
      })
    }
  }

  /**
   * Set the Privacy Policy in state if it is not already there.
   */
  _setPrivacyPolicyInState = () => {
    if (!this.state.privacyPolicy) {
      const { userPolicies } = this.props
      const privacyPolicyArr = _.filter(userPolicies, { TypeID: 1 })
      const privacyPolicy = privacyPolicyArr[0]

      if (privacyPolicy) {
        this.setState({
          privacyPolicy: privacyPolicy,
        })
      }
    }
  }

  render() {
    this._setPrivacyPolicyInState()

    return (
      <>
        <PrivacyDialog
          open={this.shouldPrivacyDialogBeOpen()}
          privacyPolicy={this.state.privacyPolicy}
          handleClose={this.togglePrivacyPolicy}
          onDecline={this.props.onDecline}
          isNew={!this.props.confirmed}
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    confirmed: state.members.confirmed,
    userPolicies: state.members.userPolicies,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getUserPolicies, updateUserPolicy }, dispatch)
}

export const PrivacyPolicyContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PrivacyPolicy)
