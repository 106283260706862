import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { COLORS } from '../../utils/colors'
import { PhoneIcon, ChatIcon } from '../../assets/icons'

export const PhoneIconStyled = styled(PhoneIcon)`
  width: 50px;
  height: 50px;
`

export const ChatIconStyled = styled(ChatIcon)`
  width: 50px;
  height: 50px;
  color: ${COLORS.BW.WHITE};
`

export const LandingContainer = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.BW.WHITE};
`

export const DesktopLandingContainer = styled.section`
  max-width: 1008px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled(Grid)`
  height: 66%;
  width: 100%;
`

export const DesktopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const CircleButton = styled(Button)`
  border-radius: 50% !important;
  width: 100px !important;
  height: 100px !important;
  background-color: ${COLORS.MEMBERS.OCEAN} !important;
  color: ${COLORS.BW.WHITE} !important;
`

export const CircleButtonDesktop = styled(Button)`
  border-radius: 50% !important;
  width: 125px !important;
  height: 125px !important;
  background-color: ${COLORS.MEMBERS.OCEAN} !important;
  color: ${COLORS.BW.WHITE} !important;
`

export const CircleButtonContainer = styled.div`
  align-items: center;
  text-align: center;
`

export const ActionButtonLabel = styled.p`
  color: ${COLORS.BW.GUNMETAL};
  font-size: 18px;
`

export const GreetingTextItem = styled(Grid)`
  text-align: center;
`

export const AuthTextContainer = styled(Grid)`
  margin-top: -15px;
`

export const WelcomeTextContainerDesktop = styled.div`
  padding-top: 10%;
`

export const WelcomeText = styled.h1`
  font-size: 26px;
  font-weight: 500;
`

export const HelpText = styled.h3`
  font-size: 30px;
`
export const HelpTextDesktop = styled.h3`
  font-size: 40px;
`

export const AuthTextDesktopContainer = styled.div`
  h5 {
    font-size: 16px;
  }
`

export const CallButtonContainerDesktop = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`

export const ChatButtonContainerDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const ButtonGridContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 25%;
`
