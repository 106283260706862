import React from 'react'
import { Link } from 'react-router-dom'
import { View } from '../../components/View'
import './style.scss'

export const Unsuccessful = (props) => {
  return (
    <View id="unsuccessful" containerClasses="unsuccessful">
      <h3>Our apologies</h3>
      <p>
        Please <a href="https://zero.health/contact-us">contact us</a> or{' '}
        <a href="tel:18558160001">call</a> for assistance.
      </p>
      <p>
        If you believe you may have entered incorrect information, please{' '}
        <Link to="/?step=4">try again</Link>.
      </p>
    </View>
  )
}
