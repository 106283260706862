// eslint-disable-next-line
export default function (state = null, action) {
  switch (action.type) {
    default:
      return {
        ...state,
        error: false,
      }
  }
}
