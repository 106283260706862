import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Walkthrough from '../views/Walkthrough'

class MemberContainer extends Component {
  render() {
    const { firstVisit, style, step } = this.props
    const showTutorial = firstVisit || step !== undefined
    const initStep = step !== undefined ? step * 1 : this.props.initStep

    return showTutorial && <Walkthrough style={style} step={initStep} />
  }
}

function mapStateToProps(state) {
  return {
    error: state.members.error,
    result: state.members.result,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

MemberContainer.propTypes = {
  initStep: PropTypes.number,
  error: PropTypes.bool,
  result: PropTypes.object,
}

MemberContainer.defaultProps = {
  initStep: 1,
  firstVisit: true,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MemberContainer)
