import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { COLORS } from '../../utils/colors'

export const LandingContainer = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.BW.CLOUDS};
`

export const DesktopLandingContainer = styled.section`
  max-width: 1008px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const DesktopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`

export const ContentContainer = styled(Grid)`
  height: 66%;
  width: 100%;
`

export const CircleButton = styled(Button)`
  border-radius: 50% !important;
  width: 100px !important;
  height: 100px !important;
  background-color: ${COLORS.MEMBERS.OCEAN} !important;
  color: ${COLORS.BW.WHITE} !important;
`

export const CircleButtonContainer = styled.div`
  align-items: center;
  text-align: center;
`
export const GreetingTextItem = styled(Grid)`
  text-align: center;
`

export const AuthTextContainer = styled(Grid)`
  margin-top: -15px;
`
export const WelcomeText = styled.h1`
  font-size: 26px;
  font-weight: 500;
`

export const HelpText = styled.h3`
  font-size: 30px;
`
export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`
