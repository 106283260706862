import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

// Styled Components
const DialogTitleStyled = styled(DialogTitle)`
  text-align: center;
  h2 {
    font-family: 'Raleway' !important;
  }
`

const ListItemStyled = styled(ListItem)`
  justify-content: center !important;
`

const ActionButton = styled(Button)`
  font-family: 'Raleway' !important;
`

interface Props {
  open: boolean
  handleClose(): void
}

export const FeatureGateDialog = ({ open, handleClose }: Props) => {
  const history = useHistory()

  // Handle the click of one of the action buttons below
  const handleClick = (routeToNavigateTo: string) => {
    history.push(routeToNavigateTo)
    handleClose()
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open} data-cy="feature-gate-dialog">
        <DialogTitleStyled id="simple-dialog-title">
          You need an account to access this feature!
        </DialogTitleStyled>
        <List>
          <ListItemStyled button onClick={() => handleClick('/how-it-works')}>
            <ActionButton>Register</ActionButton>
          </ListItemStyled>
          <ListItemStyled button onClick={() => handleClick('/login')}>
            <ActionButton>Login</ActionButton>
          </ListItemStyled>
        </List>
      </Dialog>
    </>
  )
}
