import React, { Component } from 'react'
import moment from 'moment'
import { DateInput } from '../Inputs'
import _ from 'lodash'
import { Captcha } from '../Captcha'
import './index.css'

class verify extends Component {
  state = {
    dob: '',
    firstName: '',
    lastName: '',
    last4Ssn: '',
    captcha: '',
    showValidations: false,
    valid: {
      dob: false,
      firstName: false,
      lastName: false,
      last4Ssn: false,
    },
  }

  handleCaptchaVerified = (token) => {
    this.setState({
      captcha: token,
    })
  }

  handleVerifyClick = () => {
    const { firstName, lastName, last4Ssn, dob, captcha } = this.state
    this.setState({ showValidations: true }) // once we try to verify, validation messages should show.
    if (!this.isValid()) return

    this.props.onVerifyClick({
      firstName,
      lastName,
      last4Ssn,
      dob: moment(dob).format('YYYY-MM-DD'),
      captcha,
    })
  }

  handleChange = (field) => {
    return (e) => {
      this.validateFields()
      this.setState({ [field]: e.target.value })
    }
  }

  handleSsnChange = (e) => {
    const { value } = e.target
    if (value && value.length > 4) return // max length of 4
    if (isNaN(Number(value))) return // ignore non numeric

    this.setState({ last4Ssn: value })
  }

  isDobValid = () => {
    const { dob } = this.state
    return dob && moment(dob).isValid()
  }

  isFirstNameValid = () => {
    const { firstName } = this.state
    return firstName !== undefined && firstName !== ''
  }

  isLastNameValid = () => {
    const { lastName } = this.state
    return lastName !== undefined && lastName !== ''
  }

  isLast4SsnValid = () => {
    const { last4Ssn } = this.state
    return last4Ssn && _.isNumber(last4Ssn * 1) && last4Ssn.length === 4
  }

  validateFields = () => {
    return this.isValid()
  }

  isCaptchaValid = () => {
    const { captcha } = this.state
    return captcha !== ''
  }

  getFieldValidations = () => {
    return {
      dob: this.isDobValid(),
      firstName: this.isFirstNameValid(),
      lastName: this.isLastNameValid(),
      last4Ssn: this.isLast4SsnValid(),
      captcha: this.isCaptchaValid(),
    }
  }

  isValid = () => {
    const { dob, firstName, lastName, last4Ssn, captcha } =
      this.getFieldValidations()

    return dob && firstName && lastName && last4Ssn && captcha
  }

  render() {
    const { showValidations, firstName, lastName, last4Ssn, dob } = this.state
    const valid = this.getFieldValidations()

    return (
      <div className="verify">
        <div className="verify-content">
          <h3>Verify your membership</h3>
          <div className="signup-text-block">
            Provide some details so that we can verify your membership with our
            records.
          </div>
          <div className="first-field fieldset-container">
            <fieldset
              className={
                (!valid.firstName || !valid.lastName) && showValidations
                  ? 'input-error'
                  : undefined
              }>
              <legend>Name</legend>
              <div className="fieldset-split">
                <input
                  title="first name"
                  className="field"
                  type="text"
                  placeholder="First"
                  value={firstName}
                  onChange={this.handleChange('firstName')}
                  data-cy="verify-membership-first-name"
                />
              </div>
              <div className="fieldset-split">
                <input
                  title="last name"
                  className="field"
                  type="text"
                  placeholder="Last"
                  value={lastName}
                  onChange={this.handleChange('lastName')}
                  data-cy="verify-membership-last-name"
                />
              </div>
            </fieldset>
          </div>
          <div className="fieldset-container">
            <fieldset
              className={
                !valid.last4Ssn && showValidations ? 'input-error' : undefined
              }>
              <legend>Last 4 of SSN</legend>
              <input
                className="field"
                type="text"
                placeholder="XXXX"
                value={last4Ssn}
                onChange={this.handleSsnChange}
                data-cy="verify-membership-last-4-ssn"
              />
            </fieldset>
          </div>
          <div className="fieldset-container">
            <fieldset
              className={
                !valid.dob && showValidations ? 'input-error' : undefined
              }>
              <legend>Date of Birth</legend>
              <DateInput
                className="field"
                type="text"
                placeholder="MM/DD/YYYY"
                value={dob}
                onChange={this.handleChange('dob')}
                data-cy="verify-membership-dob"
              />
            </fieldset>
          </div>
          <div className="fieldset-container">
            <Captcha onUserVerified={this.handleCaptchaVerified} />
          </div>
        </div>
        <div className="btn-footer">
          <button
            className="button yellow"
            onClick={this.handleVerifyClick}
            data-cy="verify-membership-submit">
            Verify
          </button>
        </div>
      </div>
    )
  }
}

export const Verify = verify
