import * as api from '../services/api'
import { storeUser } from '../services/user'
import { isWorking } from './GlobalActions'
export const PARSE_CALLBACK_TOKEN_SUCCESS = 'PARSE_CALLBACK_TOKEN_SUCCESS'
export const PARSING_CALLBACK_TOKEN = 'PARSING_CALLBACK_TOKEN'
export const PARSE_CALLBACK_TOKEN_ERROR = 'PARSE_CALLBACK_TOKEN_ERROR'

export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS'
export const SENDING_CODE = 'SENDING_CODE'
export const SEND_CODE_ERROR = 'SEND_CODE_ERROR'

export const LOGIN_WITH_CODE_SUCCESS = 'LOGIN_WITH_CODE_SUCCESS'
export const LOGGING_IN_WITH_CODE = 'LOGGING_IN_WITH_CODE'
export const LOGIN_WITH_CODE_ERROR = 'LOGIN_WITH_CODE_ERROR'

export const GETTING_USER_INFO = 'GETTING_USER_INFO'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR'
export const GET_USER_AUTH_STATUS = 'GET_USER_AUTH_STATUS'

export const CLEAR_VISITED = 'CLEAR_VISITED'
export const RESET_AUTH_STATE = 'RESET_AUTH_STATE'

export const getUserInfo = () => {
  return (dispatch) => {
    console.debug('Getting user info')
    dispatch(gettingUserInfo(true))
    dispatch(isWorking(true))
    api
      .get('/user')
      .then((result) => {
        const { Data } = result
        if (Data) {
          dispatch(getUserInfoSuccess(result))
          dispatch(isWorking(false))
          console.debug('Successfully got user info: ', result)
        } else {
          dispatch(getUserInfoError(result.Error))
          dispatch(isWorking(false))
          console.debug('Error fetching user info: ', result)
        }
      })
      .catch((err) => dispatch(getUserInfoError(err)))
  }
}

export const getUserInfoSuccess = (res) => {
  const { User } = res.Meta
  storeUser(User)
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: res,
  }
}

export const gettingUserInfo = (working) => {
  return {
    type: GETTING_USER_INFO,
    working,
  }
}

export const getUserInfoError = (err) => {
  return {
    type: GET_USER_INFO_ERROR,
    error: err,
  }
}

export const parseCallbackToken = (params) => {
  return (dispatch) => {
    console.debug('Parsing callback token: ', params)
    dispatch(parsingCallbackToken(true))
    dispatch(isWorking(true))
    api.parseHash(params, (user, err) => {
      if (err) {
        dispatch(parseCallbackTokenError(err))
        dispatch(isWorking(false))
        console.debug('Error parsing callback token: ', err)
      } else {
        dispatch(parseCallbackTokenSuccess(user))
        dispatch(isWorking(false))
        console.debug('Successfully parsed callback token: ', user)
      }
    })
  }
}

export const parseCallbackTokenSuccess = (res) => {
  return {
    type: PARSE_CALLBACK_TOKEN_SUCCESS,
  }
}

export const parsingCallbackToken = () => {
  return {
    type: PARSING_CALLBACK_TOKEN,
  }
}

export const parseCallbackTokenError = (err) => {
  return {
    type: PARSE_CALLBACK_TOKEN_ERROR,
    error: err,
  }
}

export const sendCode = (params) => {
  return (dispatch) => {
    console.debug('Sending code: ', params)
    dispatch(sendingCode(true))
    dispatch(isWorking(true))

    const { email } = params
    api.sendCodeEmail({ email }, (res, err) => {
      if (err) {
        console.debug('Error sending code: ', err)
        dispatch(sendCodeError(err))
        dispatch(isWorking(false))
      } else {
        console.debug('Successfully sent code: ', res)
        dispatch(sendCodeSuccess(res))
        dispatch(isWorking(false))
      }
    })
  }
}

export const sendCodeSuccess = (res) => {
  return {
    type: SEND_CODE_SUCCESS,
    payload: res,
  }
}

export const sendingCode = () => {
  return {
    type: SENDING_CODE,
  }
}

export const sendCodeError = (err) => {
  return {
    type: SEND_CODE_ERROR,
    error: err,
  }
}

export const loginWithCode = (params) => {
  return (dispatch) => {
    console.debug('Logging in with temporary code: ', params)
    dispatch(loggingInWithCode(true))
    dispatch(isWorking(true))

    const { code } = params
    api.loginWithCode({ code }, (res, err) => {
      if (err) {
        console.debug('Error logging in with code: ', err)
        dispatch(loginWithCodeError(err))
        dispatch(isWorking(false))
      } else {
        console.debug('Successfully logged in with code: ', res)
        dispatch(loginWithCodeSuccess(res))
        dispatch(isWorking(false))
      }
    })
  }
}

export const loginWithCodeSuccess = (res) => {
  return {
    type: LOGIN_WITH_CODE_SUCCESS,
    payload: res,
  }
}

export const loggingInWithCode = () => {
  return {
    type: LOGGING_IN_WITH_CODE,
  }
}

export const loginWithCodeError = (err) => {
  return {
    type: LOGIN_WITH_CODE_ERROR,
    error: err,
  }
}

export const getUserAuthStatus = () => {
  const status = {
    loggedIn: api.loggedIn(),
    token: api.getToken(),
  }

  console.debug('Got user auth status : ', status)
  return {
    type: GET_USER_AUTH_STATUS,
    payload: status,
  }
}

export const clearVisited = () => {
  return {
    type: CLEAR_VISITED,
    payload: api.resetVisited(),
  }
}

export const resetAuthState = () => {
  return {
    type: RESET_AUTH_STATE,
  }
}

export const getUserMemberships = () => {
  return api.get('/my/memberships')
}
