import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import EnterCodeContainer from '../../containers/EnterCodeContainer'
import './styles.scss'

export class Successful extends Component {
  render() {
    return (
      <div className="successful-container">
        <h3>Check your email!</h3>
        <p>
          <span className="preface">Enter your code here.</span> We have sent
          you an email with a code to log in. Enter that code here to finish
          logging in.
        </p>
        <p>
          The code will expire in 15 minutes, but if you need another one you
          can just <Link to="/login">request one here.</Link>
        </p>
        <div className="code-container">
          <EnterCodeContainer />
        </div>
        <p>
          <span className="preface">No email?</span> Contact our support
          department at{' '}
          <a href="mailto:support@zero.health">support@zero.health</a> or give
          us a <a href="tel:18558160001">call</a>
        </p>
        <p>
          If you believe you may have entered incorrect information, please{' '}
          <Link to="/login?tryAgain=true">try again</Link>.
        </p>
      </div>
    )
  }
}

Successful.defaultProps = {
  existingAccount: null, // hmmmm we could probably make the messaging more specific in this case
}
