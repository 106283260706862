import React, { useState } from 'react'
import styled from 'styled-components'
import ReactToPrint from 'react-to-print'
import { isNullDate } from '../../utils'
import { SectionHeader } from '../Headers'
import { Button } from '@material-ui/core'
import { Print } from '@material-ui/icons'
import MemberIdCard from '../MemberIdCard'

type AddToWallet = (obj: { employerMemberID: number }) => Promise<string>

interface IDCard {
  Active: boolean
  Dob: string
  EmployerMemberID: number
  EmployerName: string
  EndDate: string | null
  FirstName: string
  MiddleName: string
  LastName: string
  IntegrationID: string
  MemberID: string
  QuestID: string
  HasQuest: boolean
}

const AddToWalletContainer = styled.div`
  text-align: center;
  z-index: 10;
  :hover {
    cursor: pointer;
  }
`
const DownloadIDCardContainer = styled.div`
  text-align: center;
  margin-top: 10px;
  z-index: 10;
  :hover {
    cursor: pointer;
  }
`

const PrintAndWalletButtons: React.FC<{
  employerMemberID: number
  addToWallet: AddToWallet
  componentRef: React.MutableRefObject<null>
}> = ({ employerMemberID, addToWallet, componentRef }) => {
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null)
  const onWalletClick = () => {
    addToWallet({ employerMemberID })
      .then((url) => {
        setDownloadUrl(url)
      })
      .catch((err) => {
        setDownloadUrl(null)
      })
  }

  const addToWalletImg = `${window.location.origin}/assets/img/add-to-apple-wallet.svg`

  return (
    <div
      style={{
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'space-evenly',
      }}>
      <ReactToPrint
        trigger={() => (
          <Button
            startIcon={<Print style={{ color: '#0059CF' }} />}
            style={{ color: '#0059CF' }}>
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
      <AddToWalletContainer onClick={onWalletClick}>
        <img src={addToWalletImg} alt="Add to Apple Wallet" />
      </AddToWalletContainer>
      {downloadUrl !== null ? (
        <DownloadIDCardContainer>
          <a href={downloadUrl}>Didn't download? Click here.</a>
        </DownloadIDCardContainer>
      ) : null}
    </div>
  )
}

// this has a handle to its own ref, can be used in list or by itself
const CardWithButtons: React.FC<IDCard & { addToWallet: AddToWallet }> = (
  props
) => {
  const componentRef = React.useRef(null)

  const { addToWallet, Active, EmployerMemberID, EndDate, ...cardProps } = props

  const notYetActive = !Active && isNullDate(EndDate)

  const memberIdCardProps = {
    ...cardProps,
    IntegrationId: props.IntegrationID,
    IsCurrentlyActive: Active,
    NotYetActive: notYetActive,
  }

  return (
    <div>
      <MemberIdCard {...memberIdCardProps} />
      <PrintAndWalletButtons
        addToWallet={addToWallet}
        employerMemberID={EmployerMemberID}
        componentRef={componentRef}
      />
      <div style={{ display: 'none' }}>
        <MemberIdCard
          {...memberIdCardProps}
          componentRef={componentRef}
          print
        />
      </div>
    </div>
  )
}

interface Props {
  primary: IDCard
  dependents: IDCard[]
  addToWallet: (obj: { employerMemberID: number }) => Promise<string>
}

export const IDCardList: React.FC<Props> = (props) => {
  const { primary, dependents, addToWallet } = props

  return (
    <div className="mobile-id-card-list">
      <CardWithButtons {...primary} addToWallet={addToWallet} />

      {dependents && dependents.length > 0 && (
        <div>
          <strong>
            <SectionHeader id="dep-header" center>
              Dependents
            </SectionHeader>
          </strong>
          {dependents.map((dep: Props['dependents'][number]) => (
            <CardWithButtons {...dep} addToWallet={addToWallet} />
          ))}
        </div>
      )}
    </div>
  )
}
