import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import { emailMask } from 'text-mask-addons'
import './styles.scss'

export const SSNInput = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholder="XXX-XX-XXXX"
    />
  )
}

export const DateInput = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/[0-1]/, /\d/, '/', /\d/, /\d/, '/', /[0-2]/, /\d/, /\d/, /\d/]}
      placeholder="MM/DD/YYYY"
    />
  )
}

export const PhoneNumberInput = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholder="(___)___-____"
    />
  )
}

export const EmailInput = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={emailMask}
      placeholder="you@somedomain.com"
    />
  )
}

export class Checkbox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: props.active,
    }
  }

  onCheckChange = (e) => {
    this.setState({ checked: e.target.checked })
    this.props.onCheck(e.target.checked)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.active })
  }

  render() {
    const { label, name } = this.props
    return (
      <div className="checkbox-container">
        <input
          className="fancy-checkbox"
          type="checkbox"
          checked={this.state.checked}
          onChange={this.onCheckChange}
          disabled={this.props.disabled}
        />
        {label && <label htmlFor={name}>{label}</label>}
      </div>
    )
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
}

Checkbox.defaultProps = {
  checked: false,
}
