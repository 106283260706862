import React, { Component } from 'react'
import { DateInput, EmailInput, PhoneNumberInput } from '../Inputs'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss'

class baseFieldset extends Component {
  handleChange = (name) => {
    return (e) => {
      const value = e.target.value
      this.props.handleChange({ name, value })
    }
  }
}

baseFieldset.propTypes = {
  label: PropTypes.string.isRequired,
  validator: PropTypes.func.isRequired,
  showValidations: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  viewOnly: PropTypes.bool,
}

baseFieldset.defaultProps = {
  label: '',
  validator: () => true,
  showValidations: false,
  type: 'text',
  value: '',
  disabled: false,
  viewOnly: false,
  handleChange: ({ name, value }) =>
    console.debug(
      'No handleChange set for ',
      name,
      value,
      '. Changes will not be reflected.'
    ),
}

class textField extends baseFieldset {
  render() {
    return <MaskedField {...this.props} type="text" />
  }
}

class dateField extends baseFieldset {
  render() {
    return <MaskedField {...this.props} type="date" />
  }
}

class phoneField extends baseFieldset {
  render() {
    return <MaskedField {...this.props} type="phone" />
  }
}

class emailField extends baseFieldset {
  render() {
    return <MaskedField {...this.props} type="email" />
  }
}

class MaskedField extends baseFieldset {
  render() {
    const {
      label,
      value,
      validator,
      showValidations,
      type,
      placeholder,
      name,
      disabled,
      viewOnly,
    } = this.props
    const valid = validator(value)

    const editable = !disabled && !viewOnly
    const cls = classNames({
      'input-error': !valid && showValidations && !viewOnly,
      'view-only': viewOnly,
    })

    return (
      <FieldContainer>
        <fieldset className={cls}>
          <legend>{label}</legend>
          {type && type === 'text' && (
            <input
              className="field"
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={this.handleChange(name)}
              disabled={!editable}
            />
          )}
          {type && type === 'phone' && (
            <PhoneNumberInput
              className="field"
              value={value}
              onChange={this.handleChange(name)}
              disabled={!editable}
            />
          )}
          {type && type === 'email' && (
            <EmailInput
              className="field"
              value={value}
              onChange={this.handleChange(name)}
              disabled={!editable}
            />
          )}
          {type && type === 'date' && (
            <DateInput
              className="field"
              placeholder="MM/DD/YYYY"
              value={value}
              onChange={this.handleChange(name)}
              disabled={!editable}
            />
          )}
        </fieldset>
      </FieldContainer>
    )
  }
}

class FieldContainer extends Component {
  render() {
    return <div className="fieldset-container">{this.props.children}</div>
  }
}

export const TextField = textField
export const DateField = dateField
export const PhoneField = phoneField
export const EmailField = emailField
