import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import compose from 'recompose/compose'
import { EnterCode } from '../components/EnterCode'
import {
  loginWithCode,
  resetAuthState,
  clearVisited,
} from '../actions/AuthActions'
import {
  rLoginWithCode,
  rClickSignupEnterCode,
  rClickLogin,
} from '../analytics/AuthAnalytics'

class EnterCodeContainer extends Component {
  state = {
    code: null,
    processed: false,
  }

  componentDidMount() {
    const { location } = this.props
    const params = queryString.parse(location.search)

    if (params && params.tryAgain) {
      this.props.resetAuthState()
    }
  }

  componentWillUnmount() {
    this.props.resetAuthState()
  }

  componentWillReceiveProps(nextProps) {
    const { processed } = this.state
    if (
      nextProps &&
      nextProps.loggedIn &&
      !nextProps.loggingIn &&
      !nextProps.error &&
      !processed
    ) {
      this.setState({ processed: true })
      const { history } = this.props
      console.debug('Logged in successfully')
      rLoginWithCode()
      history.push(`/`)
      return
    }
  }

  handleSendCodeClick = ({ code }) => {
    this.setState({ code })
    this.props.loginWithCode({ code }, (err, res) => {
      console.debug('response from trying to login with code: ', res, err)
    })
    rClickLogin({ code })
  }

  handleSignup = () => {
    this.props.resetAuthState()
    this.props.clearVisited()
    this.props.history.push(`/verify`)
    rClickSignupEnterCode()
  }

  getErrorDetails = (err) => {
    if (err && err.statusCode === 400 && err.code === 'bad.connection') {
      return {
        details: (
          <span>
            We did not find an account for that email address. Please{' '}
            <Link to="/verify">click here to signup</Link>
          </span>
        ),
        title: 'Please create an account',
      }
    }
    return {
      details: (
        <span>
          Try re-entering your code or{' '}
          <Link to="/login?tryAgain=true">request a new code.</Link>
        </span>
      ),
      title: 'Your code is expired or invalid',
    }
  }

  render() {
    const { error } = this.props
    const { title, details } = this.getErrorDetails(error)

    return (
      <div className="enter-code-container">
        <EnterCode
          loginHandler={this.handleSendCodeClick}
          signupHandler={this.handleSignup}
          error={{ err: error, title, details }}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.auth.loginError,
    working: state.auth.working,
    loggingIn: state.auth.loggingIn,
    loggedIn: state.auth.loggedIn,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { resetAuthState, loginWithCode, clearVisited },
    dispatch
  )
}

EnterCodeContainer.propTypes = {
  error: PropTypes.object,
  working: PropTypes.bool,
  sending: PropTypes.bool,
  sent: PropTypes.bool,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EnterCodeContainer)
