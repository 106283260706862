import React from 'react'
import { TabNavigation } from '../../components/TabNavigation'
import { TopHeader } from '../../components/TopHeader'
import {
  ContentContainer,
  LandingContainer,
  DesktopContentContainer,
  DesktopLandingContainer,
  FlexWrapper,
} from './styledComponents'
import { isUserOnMobile } from '../../utils'
import * as api from '../../services/api' // todo: remove or make better after beta

export const Settings = (props: any) => {
  const isMobile = isUserOnMobile()

  // Render Desktop Layout if on desktop
  if (!isMobile) {
    return (
      <FlexWrapper>
        <DesktopLandingContainer>
          <TopHeader desktop />
          <DesktopContentContainer>
            <button onClick={() => api.logout()}>Logout</button>
          </DesktopContentContainer>
        </DesktopLandingContainer>
      </FlexWrapper>
    )
  }

  // Not desktop, render mobile
  return (
    <LandingContainer>
      <ContentContainer
        container
        spacing={1}
        direction="column"
        justify="space-between"
        alignItems="center">
        <TopHeader showSettingsIcon={isMobile} />
        <button onClick={() => api.logout()}>Logout</button>
      </ContentContainer>
      <TabNavigation />
    </LandingContainer>
  )
}
