import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Successful } from '../components/Successful'
import { isUserOnMobile } from '../utils'
import styled from 'styled-components'

// Styled Components
const DesktopLandingContainer = styled.section`
  max-width: 1008px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const DesktopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

class SuccessfulContainer extends Component {
  render() {
    const { existingAccount } = this.props

    const isMobile = isUserOnMobile()

    // If the user is on Desktop return the desktop layout
    if (!isMobile) {
      return (
        <FlexWrapper>
          <DesktopLandingContainer>
            <DesktopContentContainer>
              <Successful existingAccount={existingAccount} />
            </DesktopContentContainer>
          </DesktopLandingContainer>
        </FlexWrapper>
      )
    }

    // Not desktop return the mobile layout
    return (
      <div>
        <Successful existingAccount={existingAccount} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    existingAccount: state.members.existingAccount,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SuccessfulContainer)
